import axiosClient from './AxiosClient'
import DrawerController from './controllers/DrawerController'
import DisplayController from './controllers/DisplayController'
import ExhibitionController from './controllers/OverviewController'
import StoryController from './controllers/StoryController'

export class ApiService {
  public drawer: DrawerController
  public display: DisplayController
  public overview: ExhibitionController
  public story: StoryController

  constructor() {
    this.drawer = new DrawerController(axiosClient)
    this.display = new DisplayController(axiosClient)
    this.overview = new ExhibitionController(axiosClient)
    this.story = new StoryController(axiosClient)
  }
}

const api = new ApiService()
export default api
