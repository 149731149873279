import cn from 'classnames'
import { observer } from 'mobx-react'

import React from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { NAV_PATHS } from '../../common/routing/routerMap'
import { LevelValues } from '../../common/semantics'
import { GDPR } from '../../services/Typings'
import Button from '../Button/Button'
import './GdprBar.scss'

type GdprBarProps = {} & WithTranslation

const GdprBar: React.FC<GdprBarProps> = observer(({ t }) => {
  const { generalStore, drawerStore } = useStores()

  const agree = (): void => generalStore.setGdpr(GDPR.AGREED)
  const disagree = (): void => generalStore.setGdpr(GDPR.DISAGREED)

  return (
    <div className={BEM()} aria-hidden={!!generalStore.gpdr}>
      <div className="gdpr-bar__inner">
        <p className="gdpr-bar__text">
          <Trans
            i18nKey="general:gdpr.description"
            components={[
              <Link
                key={1}
                to={NAV_PATHS.gdpr()}
                tabIndex={generalStore.gpdr ? -1 : 0}
                onClick={() => drawerStore.hideDrawer(true)}
                className="gdpr-bar__link"
              />,
            ]}
          />
        </p>
        <div className="gdpr-bar__buttons">
          <Button
            variant={LevelValues.SECONDARY}
            disabled={!!generalStore.gpdr}
            onClick={agree}
            label={t('general:gdpr.allow-cta')}
          />
          <Button
            variant={LevelValues.DANGER}
            disabled={!!generalStore.gpdr}
            onClick={disagree}
            label={t('general:gdpr.disallow-cta')}
          />
        </div>
      </div>
    </div>
  )

  function BEM(): string {
    return cn('gdpr-bar', {
      'gdpr-bar--hidden': generalStore.gpdr,
    })
  }
})

export default withTranslation()(React.memo(GdprBar))
