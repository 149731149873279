import './BlockquoteBlock.scss'

import React from 'react'
import cn from 'classnames'

interface BlockquoteBlockProps {
  text: string
}

const BlockquoteBlock: React.FC<BlockquoteBlockProps> = ({ text }) => {
  return (
    <section className={BEM()}>
      <blockquote>
        <span className="blockquote-block__corner blockquote-block__corner--1" />
        <span className="blockquote-block__corner blockquote-block__corner--2" />
        <span className="blockquote-block__corner blockquote-block__corner--3" />
        <span className="blockquote-block__corner blockquote-block__corner--4" />
        {text}
      </blockquote>
    </section>
  )

  function BEM(): string {
    return cn('blockquote-block')
  }
}

export default React.memo(BlockquoteBlock)
