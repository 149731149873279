import './FileList.scss'

import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FormFields } from '../../views/Story/StoryViewFieldNames'
import CloseButton from '../CloseButton/CloseButton'

type FileListProps = {
  values: FormFields
  handleFileChange: (index: number) => void
  remove: (index: number) => void
} & WithTranslation

const FileList: React.FC<FileListProps> = ({ t, values, handleFileChange, remove }) => {
  return values.files && values.files.length ? (
    <>
      <h3>{t('story:file-upload.added-files')}</h3>
      <ol className="file-list__list">
        {values.files.map((file, index) => (
          <li key={index}>
            <div className="file-list__item">
              <div>
                <img src={file.thumbnail} alt={file.description} className="file-list__thumb" />
                <button
                  type="button"
                  className="link text--small file-list__change"
                  onClick={() => handleFileChange(index)}
                >
                  {t('story:file-upload.change-file-button')}
                </button>
              </div>
              <div className="file-list__content text--small">
                {file?.file?.name && <p className="file-list__name">{file.file.name}</p>}
                {file.description && <p className="file-list__description">{file.description}</p>}
                {file.author && (
                  <p className="file-list__author">{t('story:file-upload.author-text', { name: file.author })}</p>
                )}
                <CloseButton
                  label={t('story:file-upload.remove-file-button')}
                  className="file-list__remove"
                  onClick={() => remove(index)}
                />
              </div>
            </div>
          </li>
        ))}
      </ol>
    </>
  ) : null
}

export default withTranslation()(React.memo(FileList))
