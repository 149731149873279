import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  Slide,
  toast as originalToast,
  ToastContainer as OriginalToastContainer,
  ToastContainerProps,
  ToastContent,
  ToastId,
  ToastOptions,
} from 'react-toastify'
import CloseButton from '../CloseButton/CloseButton'

import 'react-toastify/dist/ReactToastify.min.css'
import './Toast.scss'

const CustomCloseButton = withTranslation()(
  ({ t, closeToast }: { closeToast?: () => void } & WithTranslation): JSX.Element => (
    <CloseButton
      label={t('general:close-notification')}
      className="close Toastify__close-button"
      inverted
      onClick={closeToast}
    />
  ),
)

const ToastContainer: React.FunctionComponent<ToastContainerProps> = (props) => {
  return <OriginalToastContainer {...props} />
}

ToastContainer.defaultProps = {
  autoClose: 5000,
  transition: Slide,
  position: 'bottom-center',
  closeButton: <CustomCloseButton />,
}

export const toast = {
  ...originalToast,
  success: (content: ToastContent, options?: ToastOptions): ToastId => originalToast.success(content, options),
  error: (content: ToastContent, options?: ToastOptions): ToastId => originalToast.error(content, options),
  warn: (content: ToastContent, options?: ToastOptions): ToastId => originalToast.warn(content, options),
  info: (content: ToastContent, options?: ToastOptions): ToastId => originalToast.info(content, options),
}
export default React.memo(ToastContainer)
