import dayjs from 'dayjs'
import i18next from 'i18next'
import * as Yup from 'yup'
import { fieldNames } from './StoryViewFieldNames'

export const validationSchema = Yup.lazy((values) =>
  Yup.object().shape({
    [fieldNames.story]: Yup.string()
      .required(i18next.t('story:story-section.validation.required'))
      .min(10, i18next.t('story:story-section.validation.short')),
    [fieldNames.name]: Yup.string()
      .required(i18next.t('story:personal-section.validation.name-required'))
      .min(5, i18next.t('story:personal-section.validation.name-short')),
    [fieldNames.email]: Yup.string()
      .required(i18next.t('story:personal-section.validation.email-required'))
      .email(i18next.t('story:personal-section.validation.email-invalid')),
    [fieldNames.gender]: Yup.string().required(i18next.t('story:personal-section.validation.gender-required')),
    [fieldNames.year]: Yup.number()
      .required(i18next.t('story:personal-section.validation.year-required'))
      .min(dayjs().year() - 120, i18next.t('story:personal-section.validation.year-min'))
      .max(dayjs().year(), i18next.t('story:personal-section.validation.year-max')),
    [fieldNames.agreement]: Yup.array().required(i18next.t('story:terms-section.validation.required')),
  }),
)
