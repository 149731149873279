import './TextBlock.scss'

import React from 'react'
import cn from 'classnames'
import DOMPurify from 'dompurify'
import { generateSrcSet } from '../../common/utils/generalUtil'
import { HTML, ImageDto } from '../../services/Typings'

interface TextBlockProps {
  title?: string
  text: HTML
  image?: ImageDto
  imagePosition?: 'left' | 'right'
}

const TextBlock: React.FC<TextBlockProps> = ({ title, text, image, imagePosition = 'right' }) => {
  return (
    <section className={BEM()}>
      {title && <h2>{title}</h2>}
      <div className="text-block__inner">
        {image && (
          <figure className="text-block__image">
            <img src={image.url} srcSet={generateSrcSet(image.url)} alt={image.alt_text} />
            {image.alt_text && (
              <figcaption className="text-block__caption">
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(image.alt_text) }} />
                {image?.reference && <abbr title={DOMPurify.sanitize(image.source)}>{image.reference}</abbr>}
              </figcaption>
            )}
          </figure>
        )}
        <div
          className="text-block__content text--limit"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
        />
      </div>
    </section>
  )

  function BEM(): string {
    return cn('text-block', {
      'text-block--right': image && imagePosition === 'right',
      'text-block--left': image && imagePosition === 'left',
    })
  }
}

export default React.memo(TextBlock)
