import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { initializeGA } from '../../common/utils/generalUtil'
import Banner from './components/Banner/Banner'
import { NAV_PATHS } from '../../common/routing/routerMap'
import Steps from './components/Steps/Steps'

export interface RouteProps {
  hash: string
}

export type OverviewViewProps = RouteComponentProps<RouteProps> & WithTranslation

const OverviewView: React.FC<OverviewViewProps> = observer(({ t, match, history }) => {
  const { generalStore } = useStores()

  useEffect(() => {
    if (match?.params?.hash) {
      generalStore.setHash(match.params.hash)
      initializeGA() // reinitialize GA since we have a new hash
      history.replace(NAV_PATHS.overview())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container">
      <main>
        <Banner />
        <div className="container__inner">
          <section>
            <h2>{t('overview:how-to-use-title')}</h2>
            <div className="text--limit">
              <Trans i18nKey="overview:how-to-use-description" />
            </div>
          </section>
          <Steps />
        </div>
      </main>
    </div>
  )
})

export default withTranslation()(OverviewView)
