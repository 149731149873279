import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { NAV_PATHS } from '../../common/routing/routerMap'
import DisplayContent from '../../components/DisplayContent/DisplayContent'
import Loader from '../../components/Loader/Loader'

export interface RouteProps {
  id: string
}

export type DisplayViewProps = RouteComponentProps<RouteProps>

const DisplayView: React.FC<DisplayViewProps> = observer(({ history, match }) => {
  const { generalStore, displayStore } = useStores()

  useEffect(() => {
    if (match?.params?.id) {
      displayStore.fetchDisplayContent(match.params.id)

      // when language is changed then call this API again
      generalStore.addPendingRequest('displayRequest', () => {
        displayStore.fetchDisplayContent(match.params.id)
      })

      return () => {
        generalStore.removePendingRequest('displayRequest')
        displayStore.resetPage()
      }
    } else {
      history.replace(NAV_PATHS.pageNotFound())
    }
    // eslint-disable-next-line
  }, [match?.params?.id])

  const handleMessages = (event: MessageEvent): void => {
    if (process.env.REACT_APP_TRUSTED_DIGI_HOST !== event.origin) {
      return
    }

    // loop through all the frames in the page and broadcast the same message
    for (let i = 0; i < window.frames.length; i++) {
      window.frames[i].postMessage(event.data, process.env.REACT_APP_TRUSTED_DIGI_HOST)
    }
  }

  useEffect(() => {
    // listen to message events from digi block iframes
    window.addEventListener('message', handleMessages)

    return () => {
      window.removeEventListener('message', handleMessages)
    }
  }, [])

  return (
    <div className="container">
      {!displayStore.content.length ? (
        <Loader fixed />
      ) : (
        <main className="container__main">
          <DisplayContent url={match?.params?.id} />
        </main>
      )}
    </div>
  )
})

export default DisplayView
