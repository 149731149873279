import './Steps.scss'

import React from 'react'
import { observer } from 'mobx-react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useStores } from '../../../../common/hooks'
import { LevelValues } from '../../../../common/semantics'
import Button from '../../../../components/Button/Button'

import Step1Image from '../../../../assets/step1.svg'
import Step2Image from '../../../../assets/step2.svg'

interface StepDto {
  image: string
  text: string
}

const Steps: React.FC<WithTranslation> = observer(({ t }) => {
  const { generalStore, drawerStore } = useStores()
  const steps: StepDto[] = [
    {
      image: Step1Image,
      text: t('overview:steps.step-1'),
    },
    {
      image: Step2Image,
      text: t('overview:steps.step-2'),
    },
  ]

  const handleClick = (): void => {
    if (generalStore.hash) {
      drawerStore.toggleDrawer()
    } else {
      generalStore.toggleHashModal()
    }
  }

  return (
    <section>
      <ol className="steps list--unstyled">
        {steps.map((step, index) => (
          <li key={index} className="steps__item">
            <figure className="steps__figure">
              <div className="steps__ratio-container">
                <div className="steps__ratio">
                  <img src={step.image} alt={step.text} className="steps__image" />
                </div>
              </div>
              <figcaption className="steps__caption">{step.text}</figcaption>
            </figure>
          </li>
        ))}
      </ol>
      <div className="steps__buttons">
        <Button variant={LevelValues.SECONDARY} label={t('overview:start-button')} size="lg" onClick={handleClick} />
      </div>
    </section>
  )
})

export default withTranslation()(Steps)
