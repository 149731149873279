import React from 'react'
import Helmet from 'react-helmet'
import { WithTranslation, withTranslation } from 'react-i18next'

const HelmetHead: React.FC<WithTranslation> = ({ t, i18n }) => {
  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{t('general:page-title')}</title>
      <meta property="og:site_name" content={t('general:page-title')} />
      <meta property="og:title" content={t('general:page-title')} />
      <meta name="description" content={t('general:page-description')} />
      <meta property="og:description" content={t('general:page-description')} />
      <meta property="og:url" content={window.location.origin} />
      <link rel="canonical" href={window.location.origin} />
    </Helmet>
  )
}

export default withTranslation()(React.memo(HelmetHead))
