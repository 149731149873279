import createFieldNameConstants from '../../common/utils/generalUtil'

export interface FormFields {
  hash: string
}

export const defaultInitialValues: FormFields = {
  hash: '',
}

export const fieldNames = createFieldNameConstants(defaultInitialValues)
