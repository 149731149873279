import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useStores } from '../../common/hooks'
import { BlockDto } from '../../services/controllers/DisplayController'
import { BLOCK_TYPE } from '../../services/Typings'
import BlockquoteBlock from '../BlockquoteBlock/BlockquoteBlock'
import DigiBlock from '../DigiBlock/DigiBlock'
import ExtraLinksBlock from '../ExtraLinksBlock/ExtraLinksBlock'
import GalleryBlock from '../GalleryBlock/GalleryBlock'
import ImageComparisonBlock from '../ImageComparisonBlock/ImageComparisonBlock'
import LargeImageBlock from '../LargeImageBlock/LargeImageBlock'
import NoHashBlock from '../NoHashBlock/NoHashBlock'
import ReferencesBlock from '../ReferencesBlock/ReferencesBlock'
import TextBlock from '../TextBlock/TextBlock'
import TitleBlock from '../TitleBlock/TitleBlock'
import VideoBlock from '../VideoBlock/VideoBlock'
import DisplayNavigation from '../DisplayNavigation/DisplayNavigation'

interface DisplayContentProps {
  url?: string
  isPreview?: boolean
}

type ContentType = JSX.Element | null | Array<ContentType>

const DisplayContent: React.FC<DisplayContentProps> = observer(({ url, isPreview = false }) => {
  const { displayStore, generalStore } = useStores()

  const renderContent = (content?: BlockDto[]): Array<ContentType> => {
    return (content || displayStore.content)
      .filter((block) => block.type !== BLOCK_TYPE.HEADER)
      .map((block, index) => {
        switch (block.type) {
          case BLOCK_TYPE.TEXT:
            return <TextBlock key={index} title={block.title} text={block.text} />
          case BLOCK_TYPE.TEXT_IMAGE:
            return (
              <TextBlock
                key={index}
                title={block.title}
                text={block.text}
                image={block.image}
                imagePosition={block.img_align}
              />
            )
          case BLOCK_TYPE.QUOTE:
            return <BlockquoteBlock key={index} text={block.text} />
          case BLOCK_TYPE.PICTURE:
            return <LargeImageBlock key={index} image={block.image} />
          case BLOCK_TYPE.PICTURE_COMPARE:
            return <ImageComparisonBlock key={index} title={block.title} items={block.images} />
          case BLOCK_TYPE.GALLERY:
            return <GalleryBlock key={index} title={block.title} items={block.images} />
          case BLOCK_TYPE.VIDEO:
            return <VideoBlock key={index} title={block.title} video={block.video} />
          case BLOCK_TYPE.DIGI:
            return (
              <DigiBlock
                key={index}
                title={block.title}
                url={block.url}
                reference={block.reference}
                source={block.source}
                description={block.alt_text}
              />
            )
          case BLOCK_TYPE.REFERENCE_DISPLAY:
            return renderContent(block.blocks.filter((bl) => bl.type !== BLOCK_TYPE.REFERENCE))
          case BLOCK_TYPE.EXTRA_LINKS:
            return <ExtraLinksBlock key={index} extraLinks={block.links} />
          case BLOCK_TYPE.REFERENCE:
            return (
              <ReferencesBlock key={index} literature={block.references.literature} media={block.references.media} />
            )
          default:
            return null
        }
      })
  }

  const renderHeader = useMemo(() => {
    return displayStore.content
      .filter((block) => block.type === BLOCK_TYPE.HEADER)
      .map((block, index) =>
        block.type === BLOCK_TYPE.HEADER ? (
          <TitleBlock
            author={block.author}
            key={index}
            url={url}
            breadcrumbs={block.breadcrumbs}
            title={block.title}
            intro={block.intro}
            image={block.image}
            isPreview={isPreview}
          />
        ) : null,
      )
  }, [url, isPreview, displayStore.content])

  return (
    <>
      {renderHeader}
      <div className="container__inner container__inner--display">
        {renderContent()}
        {!generalStore.hash && !isPreview ? (
          <section>
            <NoHashBlock />
          </section>
        ) : null}
      </div>
      {!isPreview && generalStore.hash ? <DisplayNavigation url={url} /> : null}
    </>
  )
})

export default DisplayContent
