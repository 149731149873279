import './VideoBlock.scss'

import React from 'react'
import cn from 'classnames'
import DOMPurify from 'dompurify'
import { VideoDto } from '../../services/Typings'
import Video from '../Video/Video'

interface VideoBlockProps {
  title?: string
  video: VideoDto
  size?: 'normal' | 'large'
}

const VideoBlock: React.FC<VideoBlockProps> = ({ size = 'medium', title, video }) => {
  return (
    <section className={BEM()}>
      {title && <h2 className="section">{title}</h2>}
      <Video url={video.url} title={title} />
      {video.alt_text && (
        <p className="text--small video-block__description">
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(video.alt_text) }} />
          {video.reference && <abbr title={DOMPurify.sanitize(video.source)}>{video.reference}</abbr>}
        </p>
      )}
    </section>
  )

  function BEM(): string {
    return cn('video-block', `video-block--${size}`)
  }
}

export default React.memo(VideoBlock)
