import React from 'react'
import { RouterStore } from 'mobx-react-router'
import DisplayStore from './DisplayStore'
import DrawerStore from './DrawerStore'
import GeneralStore from './GeneralStore'

export interface GlobalStore {
  generalStore: GeneralStore
  displayStore: DisplayStore
  drawerStore: DrawerStore
  routerStore: RouterStore
}

export const routerStore = new RouterStore()
export const globalStoreInstance = {
  generalStore: new GeneralStore(),
  displayStore: new DisplayStore(),
  drawerStore: new DrawerStore(),
  routerStore: routerStore,
}

export const globalStore = React.createContext(globalStoreInstance)
