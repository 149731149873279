import './Loader.scss'

import React from 'react'
import cn from 'classnames'

import { ReactComponent as LoaderSVG } from './loader.svg'

interface LoaderProps {
  className?: string
  inverted?: boolean
  center?: boolean
  overlay?: boolean
  backdrop?: 'white'
  fixed?: boolean
  containerSize?: 'sm' | 'md' | 'lg'
}

const Loader: React.FC<LoaderProps> = ({
  inverted,
  className,
  containerSize,
  overlay,
  backdrop,
  fixed,
  center = true,
}) => {
  return (
    <div className={BEM()}>
      <div className="loader__inner">
        <div className="loader__sand" />
        <LoaderSVG className="loader__icon" />
      </div>
    </div>
  )

  function BEM(): string {
    return cn('loader', `loader--container-${containerSize}`, `loader--backdrop-${backdrop}`, className, {
      'loader--inverted': inverted,
      'loader--center': center,
      'loader--overlay': overlay,
      'loader--fixed': fixed,
      'loader--backdrop': backdrop,
    })
  }
}

export default React.memo(Loader)
