import './FieldGroup.scss'

import React from 'react'
import cn from 'classnames'
import FieldError from '../FieldError/FieldError'

interface FieldGroup {
  label?: string
  id?: string
  name?: string
  className?: string
  children: React.ReactChild | React.ReactChild[]
  inline?: boolean
}

const FieldGroup: React.FC<FieldGroup> = ({ id, label, inline, className, name, children }) => {
  return (
    <div className={BEM()}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field__inner">
        {children}
        {name && <FieldError name={name} />}
      </div>
    </div>
  )

  function BEM(): string {
    return cn('field__group', className)
  }
}

export default React.memo(FieldGroup)
