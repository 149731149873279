import React from 'react'
import { observer } from 'mobx-react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'

export type RouteProps = {}

export type StoryViewProps = RouteComponentProps<RouteProps> & WithTranslation

const GdprView: React.FC<StoryViewProps> = observer(({ t }) => {
  return (
    <div className="container">
      <main className="container__main">
        <div className="container__inner">
          <Trans i18nKey="general:gdpr.gdpr-text" />
        </div>
      </main>
    </div>
  )
})

export default withTranslation()(GdprView)
