import React from 'react'
import { observer } from 'mobx-react'
import { Switch, Route, matchPath, Redirect } from 'react-router-dom'
import DisplayView from 'src/views/Display/DisplayView'
import OverviewView from 'src/views/Overview/OverviewView'
import HashModal from '../../components/HashModal/HashModal'
import HelmetHead from '../../components/HelmetHead/HelmetHead'
import Navigation from '../../components/Navigation/Navigation'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop'
import ToastContainer from '../../components/Toast/Toast'
import PageNotFoundView from '../../views/404/PageNotFoundView'
import GdprView from '../../views/gdpr/GdprView'
import PreviewView from '../../views/Preview/PreviewView'
import StoryView from '../../views/Story/StoryView'
import { useStores } from '../hooks'
import { ROUTER_PATHS } from './routerMap'

const Routes: React.FC = observer(() => {
  const { routerStore } = useStores()

  const showDrawer = (): boolean => {
    return (
      !!matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.DISPLAY,
      }) ||
      !!matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.OVERVIEW,
        exact: true,
      }) ||
      !!matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.PAGE_NOT_FOUND,
      }) ||
      !!matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.GDPR,
      })
    )
  }

  const headerVariant = (): 'dark' | 'light' => {
    return matchPath(routerStore.location.pathname, {
      path: ROUTER_PATHS.DISPLAY,
    }) ||
      matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.STORY,
      }) ||
      matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.PREVIEW,
      }) ||
      matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.PAGE_NOT_FOUND,
      }) ||
      matchPath(routerStore.location.pathname, {
        path: ROUTER_PATHS.GDPR,
      })
      ? 'dark'
      : 'light'
  }

  return (
    <>
      <ScrollToTop />
      <HelmetHead />
      <Navigation showDrawer={showDrawer()} variant={headerVariant()} />
      <ToastContainer />
      <HashModal />
      <Switch>
        <Route exact path={ROUTER_PATHS.OVERVIEW} component={OverviewView} />
        <Route path={ROUTER_PATHS.STORY} sensitive={true} component={StoryView} />
        <Route path={ROUTER_PATHS.DISPLAY} component={DisplayView} />
        <Route path={ROUTER_PATHS.PREVIEW} sensitive={true} component={PreviewView} />
        <Route path={ROUTER_PATHS.HASH} sensitive={true} component={OverviewView} />
        <Route path={ROUTER_PATHS.GDPR} component={GdprView} />
        <Route path={ROUTER_PATHS.PAGE_NOT_FOUND} component={PageNotFoundView} />
        <Redirect to={ROUTER_PATHS.PAGE_NOT_FOUND} />
      </Switch>
    </>
  )
})

export default Routes
