import './Navigation.scss'

import React, { useEffect } from 'react'
import cn from 'classnames'
import throttle from 'lodash-es/throttle'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { NAV_PATHS } from '../../common/routing/routerMap'
import Drawer from '../Drawer/Drawer'
import Icon, { ICON } from '../Icon/Icon'
import LanguageSelect from '../LanguageSelect/LanguageSelect'

export type NavigationProps = {
  showDrawer?: boolean
  variant?: 'dark' | 'light'
} & WithTranslation

const Navigation: React.FC<NavigationProps> = observer(({ t, showDrawer, variant = 'light' }) => {
  const { drawerStore } = useStores()
  const listenToScroll = throttle((e?: Event) => {
    if (window.pageYOffset > 1) {
      drawerStore.setPageScroll(true)
    } else {
      drawerStore.setPageScroll(false)
    }
  }, 50)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    listenToScroll()

    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  }, [listenToScroll])

  return (
    <header className={headerBEM()}>
      <nav className={BEM()}>
        <Link to={NAV_PATHS.overview()} className="nav__logo" aria-label={t('drawer:logo-a11y')}>
          <Icon name={ICON.LOGO} />
        </Link>
        <LanguageSelect variant={variant} className="nav__language" />
      </nav>
      <Drawer />
    </header>
  )

  function BEM(): string {
    return cn('nav', `${showDrawer ? 'container__inner container__inner--pull-right' : 'container__wrapper'}`, {
      'nav--page-scrolled': drawerStore.isPageScrolled,
    })
  }

  function headerBEM(): string {
    return cn('header', `header--${variant}`, {
      'page-scrolled': drawerStore.isPageScrolled,
      'header--hide-desktop-drawer': !showDrawer,
    })
  }
})

export default withTranslation()(Navigation)
