import './TitleBlock.scss'

import React, { useState } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { NAV_PATHS } from '../../common/routing/routerMap'
import DOMPurify from 'dompurify'
import { generateSrcSet } from '../../common/utils/generalUtil'
import { HTML, ImageDto } from '../../services/Typings'
import Breadcrumbs, { Breadcrumb } from '../Breadcrumbs/Breadcrumbs'
import Icon, { ICON } from '../Icon/Icon'
import isArray from 'lodash-es/isArray'

interface TitleBlockProps {
  title: string
  author?: string
  intro: HTML
  breadcrumbs: Breadcrumb[]
  image: ImageDto
  isPreview?: boolean
  url?: string
}

const TitleBlock: React.FC<TitleBlockProps & WithTranslation> = observer(
  ({ t, title, author, intro, breadcrumbs = [], url, image, isPreview }) => {
    const { generalStore } = useStores()
    const [imageError, setImageError] = useState(false)

    const enhanceBreadcrumbs = (): Breadcrumb[] =>
      breadcrumbs.map((breadcrumb, index) => {
        if (index === 0) {
          return { ...breadcrumb, url: '/' }
        } else {
          return { ...breadcrumb, url: NAV_PATHS.display({ id: breadcrumb.url || '' }) }
        }
      })

    const renderImage = (): JSX.Element | null => {
      // TODO even tho image should be required from CMS, we need to handle a case where image is missing and returned is an empty []
      if (!image || (isArray(image) && image.length === 0)) {
        return null
      }

      return (
        <div className={cn('title-block__image', { 'title-block__image--error': imageError })}>
          <img
            src={image.url}
            srcSet={generateSrcSet(image.url)}
            alt={image.alt_text}
            onError={() => setImageError(true)}
          />
        </div>
      )
    }

    return (
      <div className={BEM()}>
        <div className="title-block__inner container__inner">
          <section className="title-block__section">
            <div className="title-block__content">
              <Breadcrumbs links={enhanceBreadcrumbs()} />
              {generalStore.hash && !isPreview ? (
                <div className="container__wrapper title-block__story">
                  <Link
                    to={{
                      pathname: NAV_PATHS.story(),
                      state: url,
                    }}
                    className="title-block__story-button"
                  >
                    <Icon name={ICON.STORY} className="title-block__story-icon" />
                    {t('story:send-story-button')}
                  </Link>
                </div>
              ) : null}
              <h1 className="title-block__title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
              {author && <p className="text--small">{author}</p>}
              {renderImage()}
              <div
                className="title-block__intro text--intro"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(intro) }}
              />
            </div>
          </section>
        </div>
      </div>
    )

    function BEM(): string {
      return cn('title-block')
    }
  },
)

export default withTranslation()(TitleBlock)
