import './DisplayNavigation.scss'

import React, { useMemo } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { DrawerLinkDto } from '../../common/store/DrawerStore'
import flattenDeep from 'lodash-es/flattenDeep'
import { observer } from 'mobx-react'
import Icon, { ICON } from '../Icon/Icon'
import DOMPurify from 'dompurify'

type ReferencesBlockProps = {
  url?: string
} & WithTranslation

const DisplayNavigation: React.FC<ReferencesBlockProps> = observer(({ t, url }) => {
  const { drawerStore } = useStores()

  const navigationDisplays = useMemo(() => {
    const displays: Partial<DrawerLinkDto>[] = flattenDeep(
      drawerStore.groups?.map((g) => [{ title: g.title, url: g.url }, ...g.displays]) || [],
    )

    const index = displays.findIndex((d) => d.url === url)
    const previousDisplay: Partial<DrawerLinkDto> | null = index !== 0 ? displays[index - 1] : null
    const nextDisplay: Partial<DrawerLinkDto> | null = index !== displays.length - 1 ? displays[index + 1] : null

    return { previousDisplay, nextDisplay }
  }, [drawerStore.groups, url])

  return (
    <div className="display-navigation">
      {navigationDisplays.previousDisplay ? (
        <Link to={navigationDisplays.previousDisplay.url || ''} className="display-navigation__link">
          <Icon name={ICON.PREV} className="display-navigation__link-icon" />
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(navigationDisplays.previousDisplay.title) }} />
        </Link>
      ) : (
        <span />
      )}
      {navigationDisplays.nextDisplay ? (
        <Link to={navigationDisplays.nextDisplay.url || ''} className="display-navigation__link">
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(navigationDisplays.nextDisplay.title) }} />
          <Icon name={ICON.NEXT} className="display-navigation__link-icon" />
        </Link>
      ) : (
        <span />
      )}
    </div>
  )
})

export default withTranslation()(React.memo(DisplayNavigation))
