import dayjs from 'dayjs'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import throttle from 'lodash-es/throttle'
import debounce from 'lodash-es/debounce'
import { observer } from 'mobx-react'
import cn from 'classnames'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RemoveScroll } from 'react-remove-scroll'
import FocusTrap from 'focus-trap-react/dist/focus-trap-react'
import { Link, matchPath } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { useStores } from '../../common/hooks'
import { NAV_PATHS, ROUTER_PATHS } from '../../common/routing/routerMap'
import { DrawerLinkDto } from '../../common/store/DrawerStore'
import Button from '../Button/Button'
import GdprBar from '../GdprBar/GdprBar'
import Icon, { ICON } from '../Icon/Icon'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import Loader from '../Loader/Loader'
import NoHashBlock from '../NoHashBlock/NoHashBlock'
import DisplayLink from './components/DisplayLink/DisplayLink'

import './Drawer.scss'

export type DrawerProps = WithTranslation

const Drawer: React.FC<DrawerProps> = observer(({ t }) => {
  const { generalStore, drawerStore, routerStore } = useStores()
  const toggleRef = useRef<HTMLButtonElement>(null)
  const [hasOverflow, setHasOverflow] = useState(() => drawerStore.drawerOpen)
  const displayCount = useMemo(
    () => drawerStore.groups.reduce((accumulator, currentVal) => accumulator + currentVal.displays.length, 0),
    [drawerStore.groups],
  )

  const enableAnimations = debounce((e?: Event) => {
    document.body.classList.remove('disable-scroll')
  }, 150)
  const disableAnimations = throttle((e?: Event) => {
    if (!document.body.classList.contains('disable-scroll')) {
      document.body.classList.add('disable-scroll')
    }
    enableAnimations()
  }, 100)

  useEffect(() => {
    // if we are on preview view, then skip loading drawer content
    if (matchPath(routerStore.location.pathname, { path: ROUTER_PATHS.PREVIEW })) {
      return
    }

    drawerStore.startPolling()

    // when language is changed then call this API again
    generalStore.addPendingRequest('drawerContent', () => {
      drawerStore.resetDrawer()
      drawerStore.startPolling()
    })

    return () => {
      drawerStore.endPolling()

      generalStore.removePendingRequest('drawerContent')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const time = drawerStore.drawerOpen ? 150 : 0 // different timings for open and close

    setTimeout(() => {
      setHasOverflow(drawerStore.drawerOpen)
    }, time)

    // close drawer on escape
    if (drawerStore.drawerOpen) {
      // add event listeners
      document.onkeyup = (e) => {
        // if escape is pressed
        if (e.key === 'Escape') {
          drawerStore.toggleDrawer()

          if (toggleRef?.current) {
            toggleRef.current.focus()
          }
        }
      }
    } else {
      // remove event listeners
      document.onkeyup = null
    }
  }, [drawerStore, drawerStore.drawerOpen])

  useEffect(() => {
    // disable css animations on resize
    window.addEventListener('resize', disableAnimations)

    return () => {
      window.removeEventListener('resize', disableAnimations)
    }
  }, [disableAnimations])

  const handleClick = (): void => {
    drawerStore.toggleDrawer()
  }

  const split = (array, colCount): [DrawerLinkDto[], DrawerLinkDto[]] => {
    const splitPoint = Math.max(Math.ceil(array.length / colCount), 6)
    return [array.slice(0, splitPoint), array.slice(splitPoint)]
  }

  const renderColumns = (displays: DrawerLinkDto[]): JSX.Element[] => {
    let columnArray: Array<DrawerLinkDto[]> = []

    if (generalStore.isMobile) {
      columnArray = [displays]
    } else if (generalStore.isTablet) {
      const [column1, column2] = split(displays, 2)
      columnArray = [column1, column2]
    } else {
      const [column1, rest] = split(displays, 3)
      const [column2, column3] = split(rest, 2)
      columnArray = [column1, column2, column3]
    }

    return columnArray.map((column, index) => (
      <ul className="drawer__links list--unstyled" key={index}>
        {column.map((link, index) => (
          <li key={link.title} className="drawer__link">
            <DisplayLink index={index} isDisabled={!drawerStore.drawerOpen} title={link.title} url={link.url} />
          </li>
        ))}
      </ul>
    ))
  }

  return (
    <RemoveScroll enabled={drawerStore.drawerOpen}>
      <FocusTrap active={drawerStore.drawerOpen}>
        <div>
          <div id="drawer-element" className={BEM()}>
            <div className="drawer__overflow">
              <div className="drawer__toggle-wrapper">
                <Button
                  ref={toggleRef}
                  className="drawer__toggle"
                  onClick={() => drawerStore.toggleDrawer()}
                  aria-label={drawerStore.drawerOpen ? t('drawer:toggle-close-a11y') : t('drawer:toggle-open-a11y')}
                >
                  <span
                    className="drawer__toggle-text drawer__toggle-text--closed"
                    aria-hidden={drawerStore.drawerOpen}
                  >
                    {t('drawer:toggle-open')}
                    <Icon name={ICON.ARROW} className="drawer__icon" />
                  </span>
                  <span className="drawer__toggle-text drawer__toggle-text--open" aria-hidden={!drawerStore.drawerOpen}>
                    {t('drawer:toggle-close')}
                    <Icon name={ICON.ARROW} className="drawer__icon" />
                  </span>
                </Button>
              </div>
              <div className="drawer__top container__inner" aria-hidden={!drawerStore.drawerOpen}>
                <LanguageSelect isDisabled={!drawerStore.drawerOpen} className="drawer__languages" />
                <button
                  type="button"
                  className="drawer__burger"
                  onClick={() => drawerStore.toggleDrawer()}
                  aria-label={drawerStore.drawerOpen ? t('drawer:toggle-close-a11y') : t('drawer:toggle-open-a11y')}
                >
                  <span />
                </button>
              </div>

              <aside className="drawer__inner container__inner" aria-hidden={!drawerStore.drawerOpen}>
                <div className="section section--pull-left mt-0">
                  {!drawerStore.isLoading || drawerStore.isLoaded || drawerStore.hasError ? (
                    <div className="drawer__title-container">
                      <h2 className="mt-0 drawer__title">{t('drawer:all-saved-displays')}</h2>
                      {generalStore.hash && drawerStore.groups.length ? (
                        <>
                          <p className="drawer__description text--sub mt-0">
                            {t('drawer:description', {
                              count: displayCount,
                              time: drawerStore.lastTagged ? dayjs(drawerStore.lastTagged).fromNow() : '',
                            })}
                          </p>
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  {drawerStore.isLoading && !drawerStore.isLoaded && !drawerStore.hasError ? (
                    <Loader inverted overlay />
                  ) : !generalStore.hash ? (
                    <NoHashBlock onClick={handleClick} />
                  ) : drawerStore.groups.length ? (
                    drawerStore.groups.map((group, index) => (
                      <div className="drawer__group" key={group.title} id={`drawer-group-${group.id}`}>
                        {group.url ? (
                          <Link
                            to={NAV_PATHS.display({ id: group.url })}
                            onClick={() => drawerStore.toggleDrawer()}
                            className="drawer__group-link text--sub mt-0"
                            tabIndex={!drawerStore.drawerOpen ? -1 : 0}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(group.title) }}
                          />
                        ) : (
                          <p className="drawer__group-link text--sub mt-0">{group.title}</p>
                        )}
                        {group.displays.length ? (
                          <div className="drawer__displays">{renderColumns(group.displays)}</div>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <>
                      <p className="text--limit-lg mt-0">{t('drawer:no-displays')}</p>
                    </>
                  )}
                </div>
              </aside>
            </div>
          </div>
          <GdprBar />
        </div>
      </FocusTrap>
    </RemoveScroll>
  )

  function BEM(): string {
    return cn('drawer', {
      'drawer--open': drawerStore.drawerOpen,
      'drawer--has-overflow': hasOverflow,
    })
  }
})

export default withTranslation()(Drawer)
