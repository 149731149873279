import 'react-app-polyfill/stable'
import smoothscroll from 'smoothscroll-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import './scss/themes/default.scss'
import './scss/main.scss'

import App from './App'
import { initializeGA } from './common/utils/generalUtil'
import * as serviceWorker from './serviceWorker'

Modal.setAppElement('#root')
smoothscroll.polyfill()

initializeGA()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
