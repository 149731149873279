import { EventEmitter } from 'events'

/*
  Help to loop API requests.
  A new timeout time can be set based on the API response (BE can control polling time)
 */
export default class Poller extends EventEmitter {
  timeout: number | undefined = undefined
  timeoutInstance: number | null = null
  cb

  constructor(timeout = 0) {
    super()
    this.timeout = timeout
  }

  poll(newTime: number): void {
    if (this.timeoutInstance) {
      clearTimeout(this.timeoutInstance)
    }

    this.timeout = newTime !== undefined ? newTime : this.timeout
    // requestAnimationFrame makes sure that we pause polling when browser tab is not focused
    this.timeoutInstance = setTimeout(() => requestAnimationFrame(() => this.emit('poll')), this.timeout)
  }

  clearPoll(): void {
    if (this.timeoutInstance) {
      clearTimeout(this.timeoutInstance)
    }
  }

  endPoll(): void {
    if (this.timeoutInstance) {
      clearTimeout(this.timeoutInstance)
    }

    if (this.cb) {
      this.off('poll', this.cb)
    }
  }

  onPoll(cb): void {
    if (this.cb) {
      this.off('poll', this.cb)
    }

    this.cb = cb
    this.on('poll', this.cb)
  }
}
