import createFieldNameConstants from '../../common/utils/generalUtil'
import { FileDto, GENDER } from '../../services/Typings'

export interface FormFields {
  agreement: string[]
  email: string
  files: FileDto[]
  gender: GENDER | undefined
  name: string
  story: string
  year: string
}

export const defaultInitialValues: FormFields = {
  agreement: [],
  email: '',
  files: [],
  gender: undefined,
  name: '',
  story: '',
  year: '',
}

export const fieldNames = createFieldNameConstants(defaultInitialValues)
