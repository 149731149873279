import './Lighbox.scss'

import React, { useMemo } from 'react'
import ImageGallery from 'react-image-gallery'
import ReactModal from 'react-modal'
import { observer } from 'mobx-react'
import { useStores } from '../../common/hooks'
import { generateSrcSet } from '../../common/utils/generalUtil'
import DOMPurify from 'dompurify'
import { GalleryItemDto } from '../../services/Typings'
import Icon, { ICON } from '../Icon/Icon'
import Modal from '../Modal/Modal'

interface LightBoxProps {
  closeModal?: () => void
  initialIndex: number
  isOpen: boolean
  items: GalleryItemDto[]
  title?: string
}

const LightBox: React.FC<LightBoxProps> = observer(({ isOpen, initialIndex, title, items, closeModal }) => {
  const { generalStore } = useStores()
  const lightboxStyle: ReactModal.Styles = {
    content: {
      padding: generalStore.isMobile ? '2rem 1rem' : '2rem 5rem',
    },
  }

  const renderLeftNav = (onClick, disabled): JSX.Element => (
    <button className="image-gallery-left-nav" type="button" disabled={disabled} onClick={onClick}>
      <Icon name={ICON.PREV} />
    </button>
  )

  const renderRightNav = (onClick, disabled): JSX.Element => (
    <button className="image-gallery-right-nav" type="button" disabled={disabled} onClick={onClick}>
      <Icon name={ICON.NEXT} />
    </button>
  )

  // overrides item render due to need to add reference in the description
  // this however removes the possibility for onLoad and onError events
  const renderItem = (item): JSX.Element => {
    return (
      <div>
        <img
          className="image-gallery-image"
          src={item.original}
          alt={item.originalAlt}
          srcSet={item.srcSet}
          sizes={item.sizes}
          title={item.originalTitle}
        />

        {item.description && (
          <div className="image-gallery-description">
            <span>{item.description}</span>
            {item.reference && <abbr title={DOMPurify.sanitize(item.source)}>{item.reference}</abbr>}
          </div>
        )}
      </div>
    )
  }

  const parseItems = useMemo(
    () =>
      items.map((item) => ({
        description: item.alt_text,
        original: item.url,
        srcSet: generateSrcSet(item.url),
        originalAlt: item.alt_text,
        thumbnail: item.thumbnail_url,
        thumbnailAlt: item.alt_text,
        reference: item.reference,
        source: item.source,
      })),
    [items],
  )

  return (
    <Modal
      isOpen={isOpen}
      size="large"
      style={lightboxStyle}
      onRequestClose={closeModal}
      contentLabel={title}
      className="lightbox__modal"
    >
      <h4 className="mt-0 lightbox__title">{title}</h4>
      <ImageGallery
        lazyLoad={true}
        items={parseItems}
        showBullets={false}
        showFullscreenButton={false}
        showThumbnails={false}
        showPlayButton={false}
        renderItem={renderItem}
        startIndex={initialIndex}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
      />
    </Modal>
  )
})

export default LightBox
