import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Field, FieldArray, Formik } from 'formik'
import TextareaAutosize from 'react-autosize-textarea'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { NAV_PATHS } from '../../common/routing/routerMap'
import { LevelValues } from '../../common/semantics'
import Button from '../../components/Button/Button'
import Checkbox from '../../components/Checkbox/Checkbox'
import FieldGroup from '../../components/FieldGroup/FieldGroup'
import FileUploader from '../../components/FileUploader/FileUploader'
import Modal from '../../components/Modal/Modal'
import { toast } from '../../components/Toast/Toast'
import api from '../../services/Api.services'
import { GENDER } from '../../services/Typings'
import { defaultInitialValues, fieldNames, FormFields } from './StoryViewFieldNames'
import { validationSchema } from './StoryViewValidation'

export type RouteProps = {}

export type StoryViewProps = RouteComponentProps<RouteProps> & WithTranslation

const StoryView: React.FC<StoryViewProps> = observer(({ t, location, history }) => {
  const { generalStore, routerStore } = useStores()
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    if (!generalStore.hash) {
      history.replace(NAV_PATHS.overview())
    }
  }, [generalStore, history])

  const enhanceInitialValues = (): FormFields => ({
    ...defaultInitialValues,
    email: localStorage.getItem('storyEmail') || '',
    name: localStorage.getItem('storyName') || '',
    year: localStorage.getItem('storyYear') || '',
    gender: (localStorage.getItem('storyGender') as GENDER) || '',
  })

  const handleSubmit = async (values: FormFields): Promise<void> => {
    try {
      await api.story.formSubmit(values, (location.state as string) || '')
      setIsSuccess(true)
      localStorage.setItem('storyEmail', values.email)
      localStorage.setItem('storyName', values.name)
      localStorage.setItem('storyGender', values.gender || '')
      localStorage.setItem('storyYear', values.year)
    } catch (e) {
      toast.error(i18next.t('story:error'))
    }
  }

  const renderSuccessModal = (): JSX.Element => {
    return (
      <Modal isOpen={isSuccess} type="success">
        <h3 className="mt-0">{t('story:success-modal.title')}</h3>
        <p>{t('story:success-modal.text')}</p>
        <Link
          to={location.state ? NAV_PATHS.display({ id: location.state as string }) : NAV_PATHS.overview()}
          onClick={routerStore.history.goBack}
          replace={true}
          className="btn btn--primary mt-3"
        >
          {t('story:success-modal.CTA')}
        </Link>
      </Modal>
    )
  }

  return (
    <div className="container">
      <main className="container__main">
        <div className="container__inner">
          <Formik
            initialValues={enhanceInitialValues()}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleReset, handleSubmit }) => {
              return (
                <form onReset={handleReset} onSubmit={handleSubmit}>
                  <div>
                    <h1>{t('story:story-title')}</h1>
                    <p>{t('story:story-description')}</p>
                  </div>
                  <div>
                    <h2 className="mb-1">{t('story:story-section.title')}</h2>
                    <FieldGroup label={t('story:story-section.label')} id="story-id" name={fieldNames.story}>
                      <Field
                        id="story-id"
                        name={fieldNames.story}
                        as={TextareaAutosize}
                        className="input--large"
                        invalid={touched[fieldNames.story] && errors[fieldNames.story]}
                      />
                    </FieldGroup>
                    <FieldArray
                      name={fieldNames.files}
                      render={(arrayHelpers) => <FileUploader {...arrayHelpers} fileValues={values} />}
                    />
                  </div>
                  <div>
                    <h2 className="mb-1">{t('story:personal-section.title')}</h2>
                    <FieldGroup label={t('story:personal-section.name-label')} id="name-id" name={fieldNames.name}>
                      <Field
                        id="name-id"
                        name={fieldNames.name}
                        type="text"
                        invalid={touched[fieldNames.name] && errors[fieldNames.name]}
                      />
                    </FieldGroup>
                    <FieldGroup label={t('story:personal-section.email-label')} id="email-id" name={fieldNames.email}>
                      <Field
                        id="email-id"
                        name={fieldNames.email}
                        type="text"
                        invalid={touched[fieldNames.email] && errors[fieldNames.email]}
                      />
                    </FieldGroup>
                    <FieldGroup label={t('story:personal-section.gender-label')} name={fieldNames.gender}>
                      <Checkbox
                        name={fieldNames.gender}
                        type="radio"
                        label={t('story:genders.male')}
                        value={GENDER.MALE}
                        id="gender-4"
                      />
                      <Checkbox
                        name={fieldNames.gender}
                        type="radio"
                        label={t('story:genders.female')}
                        value={GENDER.FEMALE}
                        id="gender-5"
                      />
                      <Checkbox
                        name={fieldNames.gender}
                        type="radio"
                        label={t('story:genders.other')}
                        value={GENDER.OTHER}
                        id="gender-6"
                      />
                    </FieldGroup>
                    <FieldGroup label={t('story:personal-section.dob-label')} id="year-id" name={fieldNames.year}>
                      <Field
                        id="year-id"
                        name={fieldNames.year}
                        type="text"
                        invalid={touched[fieldNames.year] && errors[fieldNames.year]}
                        inputMode="numeric"
                        className="input--x-small"
                        maxLength="4"
                      />
                    </FieldGroup>
                  </div>
                  <div>
                    <h2>{t('story:terms-section.title')}</h2>
                    <a href="/" target="__blank" rel="noopener noreferrer" className="mt-1 mb-1">
                      {t('story:terms-section.read-terms')}
                    </a>
                    <FieldGroup name={fieldNames.agreement}>
                      <Checkbox
                        name={fieldNames.agreement}
                        label={t('story:terms-section.agree-label')}
                        value="agreed"
                        id="agreement-check"
                        required
                      />
                    </FieldGroup>
                    <Button variant={LevelValues.SECONDARY} type="submit" label={t('story:submit')} />
                  </div>
                </form>
              )
            }}
          </Formik>
          {renderSuccessModal()}
        </div>
      </main>
    </div>
  )
})

export default withTranslation()(StoryView)
