export interface GalleryItemDto extends ImageDto {
  thumbnail_url: string
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum GDPR {
  AGREED = 'agreed',
  DISAGREED = 'disagreed',
}

export enum BLOCK_TYPE {
  HEADER = 'header',
  TEXT = 'text',
  TEXT_IMAGE = 'text-img',
  PICTURE = 'picture',
  VIDEO = 'video',
  GALLERY = 'gallery',
  DIGI = 'digi',
  PICTURE_COMPARE = 'picture-compare',
  REFERENCE_DISPLAY = 'reference-displays',
  QUOTE = 'quote',
  REFERENCE = 'reference',
  EXTRA_LINKS = 'extra-links',
}

export interface ImageDto {
  url: string
  alt_text?: string
  author?: string
  reference?: string
  source?: HTML
}

export interface VideoDto {
  url: string
  alt_text?: string
  author?: string
  reference?: string
  source?: HTML
}

export interface FileDto {
  id: number
  author: string
  description: string
  file: File | undefined
  fileId: string
  thumbnail: string
}

export interface MediaReferenceDto {
  id: string
  reference: string
  source: HTML
}

export interface LiteratureReferenceDto {
  id: string
  text: HTML
}

export type HTML = string
export type ISO8601 = string
