import { createBrowserHistory } from 'history'
import { syncHistoryWithStore } from 'mobx-react-router'
import React from 'react'
import { Router } from 'react-router-dom'
import './common/translation/translationConfig'
import Routes from './common/routing/Router'
import { routerStore } from './common/store/Stores'

const App: React.FC = () => {
  const browserHistory = createBrowserHistory()
  const history = syncHistoryWithStore(browserHistory, routerStore)

  return (
    <Router history={history}>
      <Routes />
    </Router>
  )
}

export default App
