/* eslint-disable @typescript-eslint/no-explicit-any */
import './Button.scss'

import React from 'react'
import cn from 'classnames'
import Icon, { ICON } from '../Icon/Icon'
import { LevelValues, Size } from '../../common/semantics'

export interface ButtonProps {
  children?: React.ReactChild | React.ReactChild[]
  href?: string
  target?: string
  disabled?: boolean
  type?: 'button' | 'submit'
  label?: string
  iconRight?: ICON
  iconLeft?: ICON
  variant?: LevelValues
  size?: Size
  tiled?: boolean
  fill?: boolean
  className?: string
  align?: 'left' | 'center' | 'right'
  onClick?: (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
}

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      href,
      target,
      children,
      className,
      disabled,
      type = 'button',
      label,
      iconLeft,
      iconRight,
      variant,
      size,
      tiled,
      fill,
      align,
      onClick,
    },
    ref,
  ) => {
    const ButtonTag = href ? 'a' : 'button'
    const anchorProps = href && target === '_blank' ? { rel: 'noopener noreferrer', target } : { target }

    return (
      <ButtonTag
        ref={ref as any}
        className={BEM()}
        onClick={onClick}
        disabled={disabled}
        type={type}
        data-testid="button-tag"
        {...anchorProps}
      >
        {!!iconLeft && <Icon className="btn__icon" name={iconLeft} />}
        {(!!label || !!children) && <span className="btn__label">{label || children}</span>}
        {!!iconRight && <Icon className="btn__icon" name={iconRight} />}
      </ButtonTag>
    )

    function BEM(): string {
      return cn('btn', className, `btn--${variant}`, `btn--${size}`, `btn--align-${align}`, {
        'btn--tiled': tiled,
        'btn--fill': fill,
      })
    }
  },
)

export default React.memo(Button)
