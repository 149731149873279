import './ImageComparisonBlock.scss'

import React from 'react'
import cn from 'classnames'
import DOMPurify from 'dompurify'
import { generateSrcSet } from '../../common/utils/generalUtil'
import { ImageDto } from '../../services/Typings'

interface ImageComparisonBlockProps {
  title?: string
  items: ImageDto[]
}

const ImageComparisonBlock: React.FC<ImageComparisonBlockProps> = ({ title, items = [] }) => {
  return (
    <section className={BEM()}>
      {title && <h2>{title}</h2>}
      <div className="image-comparison-block__items">
        {items.map((item, index) => (
          <figure className="image-comparison-block__figure" key={index}>
            <div className="image-comparison-block__ratio">
              <img
                src={item.url}
                srcSet={generateSrcSet(item.url)}
                alt={item.alt_text}
                className="image-comparison-block__thumbnail"
              />
            </div>
            {item.alt_text && (
              <figcaption className="image-comparison-block__description">
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.alt_text) }} />
                {item.reference && <abbr title={DOMPurify.sanitize(item.source)}>{item.reference}</abbr>}
              </figcaption>
            )}
          </figure>
        ))}
      </div>
    </section>
  )

  function BEM(): string {
    return cn('image-comparison-block')
  }
}

export default React.memo(ImageComparisonBlock)
