import './ReferenceBlock.scss'

import React from 'react'
import cn from 'classnames'
import DOMPurify from 'dompurify'
import { withTranslation, WithTranslation } from 'react-i18next'
import { LiteratureReferenceDto, MediaReferenceDto } from '../../services/Typings'

type ReferencesBlockProps = {
  media: MediaReferenceDto[]
  literature: LiteratureReferenceDto[]
} & WithTranslation

const ReferencesBlock: React.FC<ReferencesBlockProps> = ({ t, media = [], literature = [] }) => {
  return media.length || literature.length ? (
    <section className={BEM()}>
      <h2>{t('general:blocks.references.title')}</h2>
      {media.length ? (
        <>
          <p>{t('general:blocks.references.sources')}</p>
          <ul className="reference-block__list list--unstyled">
            {media.map((item) => (
              <li key={item.id}>
                {item.reference}
                {item.source && (
                  <>
                    {' = '}
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.source) }} />
                  </>
                )}
              </li>
            ))}
          </ul>
        </>
      ) : null}
      {literature.length ? (
        <>
          <p>{t('general:blocks.references.literature')}</p>
          <ol className="reference-block__list">
            {literature.map((lit) => (
              <li
                id={`reference-${lit.id}`}
                key={`reference-${lit.id}`}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lit.text) }}
              />
            ))}
          </ol>
        </>
      ) : null}
    </section>
  ) : null

  function BEM(): string {
    return cn('references-block')
  }
}

export default withTranslation()(React.memo(ReferencesBlock))
