import './Breadcrumbs.scss'

import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'

interface BreadcrumbsProps {
  links?: Breadcrumb[]
}

export interface Breadcrumb {
  label: string
  url?: string
  id: string
  onClick?: (link: Breadcrumb, e) => void
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ links = [] }) => {
  const handleClick = (e, breadcrumb: Breadcrumb): void => {
    if (breadcrumb.onClick) {
      breadcrumb.onClick(breadcrumb, e)
    }
  }

  return (
    <ul className={BEM()}>
      {links.map((link, index) => (
        <li key={index} className="breadcrumbs__item">
          {index === links.length - 1 ? (
            <span className="breadcrumbs__link" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.label) }} />
          ) : link.url ? (
            <Link
              to={link.url}
              onClick={(e) => handleClick(e, link)}
              className="breadcrumbs__link"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.label) }}
            />
          ) : (
            <button
              type="button"
              onClick={(e) => handleClick(e, link)}
              className="link breadcrumbs__link"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.label) }}
            />
          )}
        </li>
      ))}
    </ul>
  )

  function BEM(): string {
    return cn('breadcrumbs', 'list--unstyled')
  }
}

export default React.memo(Breadcrumbs)
