import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useStores } from '../../common/hooks'

const ScrollToTop: React.FC = observer(() => {
  const { routerStore } = useStores()

  useEffect(() => {
    window.scrollTo(0, 0)
    ReactGA.pageview(routerStore.location.pathname + window.location.search)
  }, [routerStore.location.pathname])

  return null
})

export default ScrollToTop
