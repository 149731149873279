import dayjs from 'dayjs'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGES, NAMESPACES } from '../constants'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

import DE_GENERAL from './locales/de/general.json'
import EN_GENERAL from './locales/en/general.json'
import ET_GENERAL from './locales/et/general.json'
import FI_GENERAL from './locales/fi/general.json'
import FR_GENERAL from './locales/fr/general.json'
import LV_GENERAL from './locales/lv/general.json'
import RU_GENERAL from './locales/ru/general.json'

import DE_DRAWER from './locales/de/drawer.json'
import EN_DRAWER from './locales/en/drawer.json'
import ET_DRAWER from './locales/et/drawer.json'
import FI_DRAWER from './locales/fi/drawer.json'
import FR_DRAWER from './locales/fr/drawer.json'
import LV_DRAWER from './locales/lv/drawer.json'
import RU_DRAWER from './locales/ru/drawer.json'

import DE_OVERVIEW from './locales/de/overview.json'
import EN_OVERVIEW from './locales/en/overview.json'
import ET_OVERVIEW from './locales/et/overview.json'
import FI_OVERVIEW from './locales/fi/overview.json'
import FR_OVERVIEW from './locales/fr/overview.json'
import LV_OVERVIEW from './locales/lv/overview.json'
import RU_OVERVIEW from './locales/ru/overview.json'

import DE_STORY from './locales/de/story.json'
import EN_STORY from './locales/en/story.json'
import ET_STORY from './locales/et/story.json'
import FI_STORY from './locales/fi/story.json'
import FR_STORY from './locales/fr/story.json'
import LV_STORY from './locales/lv/story.json'
import RU_STORY from './locales/ru/story.json'

import 'dayjs/locale/et'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import 'dayjs/locale/fi'
import 'dayjs/locale/lv'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

const resources = {
  [LANGUAGES.DE]: {
    [NAMESPACES.GENERAL]: DE_GENERAL,
    [NAMESPACES.DRAWER]: DE_DRAWER,
    [NAMESPACES.OVERVIEW]: DE_OVERVIEW,
    [NAMESPACES.STORY]: DE_STORY,
  },
  [LANGUAGES.EN]: {
    [NAMESPACES.GENERAL]: EN_GENERAL,
    [NAMESPACES.DRAWER]: EN_DRAWER,
    [NAMESPACES.OVERVIEW]: EN_OVERVIEW,
    [NAMESPACES.STORY]: EN_STORY,
  },
  [LANGUAGES.ET]: {
    [NAMESPACES.GENERAL]: ET_GENERAL,
    [NAMESPACES.DRAWER]: ET_DRAWER,
    [NAMESPACES.OVERVIEW]: ET_OVERVIEW,
    [NAMESPACES.STORY]: ET_STORY,
  },
  [LANGUAGES.FI]: {
    [NAMESPACES.GENERAL]: FI_GENERAL,
    [NAMESPACES.DRAWER]: FI_DRAWER,
    [NAMESPACES.OVERVIEW]: FI_OVERVIEW,
    [NAMESPACES.STORY]: FI_STORY,
  },
  [LANGUAGES.FR]: {
    [NAMESPACES.GENERAL]: FR_GENERAL,
    [NAMESPACES.DRAWER]: FR_DRAWER,
    [NAMESPACES.OVERVIEW]: FR_OVERVIEW,
    [NAMESPACES.STORY]: FR_STORY,
  },
  [LANGUAGES.LV]: {
    [NAMESPACES.GENERAL]: LV_GENERAL,
    [NAMESPACES.DRAWER]: LV_DRAWER,
    [NAMESPACES.OVERVIEW]: LV_OVERVIEW,
    [NAMESPACES.STORY]: LV_STORY,
  },
  [LANGUAGES.RU]: {
    [NAMESPACES.GENERAL]: RU_GENERAL,
    [NAMESPACES.DRAWER]: RU_DRAWER,
    [NAMESPACES.OVERVIEW]: RU_OVERVIEW,
    [NAMESPACES.STORY]: RU_STORY,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || LANGUAGES.ET,
  fallbackLng: LANGUAGES.EN,
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['h1', 'h2', 'section', 'br', 'strong', 'a', 'b', 'i', 'p'],
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

// TODO add relativeTime translations for lv since they are currently missing
// https://github.com/iamkun/dayjs/blob/dev/src/locale/lv.js
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
dayjs.updateLocale('lv', {
  relativeTime: {
    future: 'pēc %s',
    past: 'pirms %s',
    s: 'dažas sekundes',
    m: 'minūtes',
    mm: '%d minūtēm',
    h: 'stundas',
    hh: '%d stundām',
    d: 'dienas',
    dd: '%d dienām',
    M: 'mēneša',
    MM: '%d mēnešiem',
    y: 'gada',
    yy: '%d gadiem',
  },
})

dayjs.locale(localStorage.getItem('lang') || LANGUAGES.ET)

export default i18n
