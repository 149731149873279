import './Icon.scss'

import React from 'react'
import cn from 'classnames'

import { ReactComponent as Arrow } from './Icons/arrow.svg'
import { ReactComponent as Logo } from './Icons/logo.svg'
import { ReactComponent as Next } from './Icons/next.svg'
import { ReactComponent as Prev } from './Icons/prev.svg'
import { ReactComponent as Story } from './Icons/story.svg'
import { ReactComponent as Tick } from './Icons/tick.svg'

export const ICON = {
  ARROW: Arrow,
  LOGO: Logo,
  NEXT: Next,
  PREV: Prev,
  STORY: Story,
  TICK: Tick,
}

export type ICON = EnumLiteralsOf<typeof ICON>
export interface IconProps {
  name: ICON
  className?: string
}

const Icon: React.FC<IconProps> = ({ name, className }: IconProps) => {
  const IconRender = name
  return <IconRender data-testid="icon" className={BEM()} />

  function BEM(): string {
    return cn('icon', className)
  }
}

export default React.memo(Icon)
