import ReactGA from 'react-ga'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import CSSVariables from '../../scss/_variables.scss'
import { GDPR } from '../../services/Typings'

/**
 * JS detection for mobile device based on screen size and mobile breakpoint in CSS
 */
export const isMobileView = (windowWidth?: number): boolean => {
  return CSSVariables && (windowWidth || window.innerWidth) < parseInt(CSSVariables.mobileBreakpoint, 10)
}

/**
 * JS detection for tablet device based on screen size and mobile breakpoint in CSS
 */
export const isTabletView = (windowWidth?: number): boolean => {
  return CSSVariables && (windowWidth || window.innerWidth) < parseInt(CSSVariables.tabletBreakpoint, 10)
}

/**
 * @returns object that has the same properties as given object, but values are set based on property names.
 * Returned object type is both read-only (to avoid reassigning values)
 * and type-safely guaranteed to have the same properties as given object (so that IDE can autocomplete properties based on given object type properties).
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createFieldNameConstants<K extends string, T>(obj: Record<K, T>): Readonly<Record<K, any>> {
  return fieldNames(obj)
}

function fieldNames<K extends string, T>(obj: Record<K, T>): Record<K, string> {
  const res = {} as Record<K, string> & { [key: string]: string }
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  Object.keys(obj).forEach((propName) => (res[propName] = propName))
  return res
}

export const generateSrcSet = (url: string): string => {
  const index = url.lastIndexOf('.')
  const filename = url.substring(0, index)
  const extension = url.substring(index + 1)

  return url + `, ${filename}@2x.${extension} 2x, ${filename}@3x.${extension} 3x`
}

export const initializeGA = (anonymous?: boolean): void => {
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_GA &&
    localStorage.getItem('gdpr') === GDPR.AGREED &&
    (anonymous || localStorage.getItem('hash'))
  ) {
    ReactGA.initialize(process.env.REACT_APP_GA, {
      gaOptions: {
        userId: anonymous || !localStorage.getItem('hash') ? '' : (localStorage.getItem('hash') as string),
      },
    })
  }
}
