// supported languages
export enum LANGUAGES {
  ET = 'et',
  EN = 'en',
  RU = 'ru',
  FI = 'fi',
  LV = 'lv',
  DE = 'de',
  FR = 'fr',
}

export const LANGUAGE_MAP = {
  [LANGUAGES.ET]: 'eesti',
  [LANGUAGES.EN]: 'english',
  [LANGUAGES.RU]: 'pусский',
  [LANGUAGES.FI]: 'suomi',
  [LANGUAGES.LV]: 'latviešu',
  [LANGUAGES.DE]: 'deutsch',
  [LANGUAGES.FR]: 'français',
}

// available translation namespaces
export enum NAMESPACES {
  GENERAL = 'general',
  DRAWER = 'drawer',
  OVERVIEW = 'overview',
  STORY = 'story',
}

export const POLLER_FALLBACK_TIME = 10000 // 10s

export const FILE_SIZE_LIMIT = 15000000 // 15MB
export const SUPPORTED_IMAGE_FORMATS: string[] = ['image/jpg', 'image/jpeg', 'image/png']
export const SUPPORTED_VIDEO_FORMATS: string[] = []
export const SUPPORTED_DOCUMENT_FORMATS: string[] = [
  'text/plain', // .txt
  'application/pdf', // .pdf
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.oasis.opendocument.text', // .odt
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
]
export const FILE_TYPE_RESTRICTION = [
  ...SUPPORTED_IMAGE_FORMATS,
  ...SUPPORTED_VIDEO_FORMATS,
  ...SUPPORTED_DOCUMENT_FORMATS,
]
