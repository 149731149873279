import './FieldError.scss'

import React from 'react'
import { ErrorMessage, ErrorMessageProps } from 'formik'

type FieldError = ErrorMessageProps

const FieldError: React.FC<FieldError> = (props) => {
  return <ErrorMessage {...props} render={(msg) => <div className="field__error">{msg}</div>} />
}

export default React.memo(FieldError)
