import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router-dom'
import { useStores } from '../../common/hooks'
import { NAV_PATHS } from '../../common/routing/routerMap'
import DisplayContent from '../../components/DisplayContent/DisplayContent'
import Loader from '../../components/Loader/Loader'

export interface RouteProps {
  hash: string
}

export type PreviewViewProps = RouteComponentProps<RouteProps>

const PreviewView: React.FC<PreviewViewProps> = observer(({ history, match }) => {
  const { generalStore, displayStore } = useStores()

  const handleMessages = (event: MessageEvent): void => {
    if (process.env.REACT_APP_TRUSTED_DIGI_HOST !== event.origin) {
      return
    }

    // loop through all the frames in the page and broadcast the same message
    for (let i = 0; i < window.frames.length; i++) {
      window.frames[i].postMessage(event.data, process.env.REACT_APP_TRUSTED_DIGI_HOST)
    }
  }

  useEffect(() => {
    // get preview hash from url
    if (match?.params?.hash) {
      displayStore.startPolling(match.params.hash)

      // listen to message events from digi block iframes
      window.addEventListener('message', handleMessages)

      // when language is changed then call this API again
      generalStore.addPendingRequest('previewRequest', () => displayStore.fetchPreviewContent(match.params.hash))

      return () => {
        window.removeEventListener('message', handleMessages)
        generalStore.removePendingRequest('previewRequest')
        displayStore.endPolling()
        displayStore.resetPage()
      }
    } else {
      history.replace(NAV_PATHS.pageNotFound())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="container">
      {!displayStore.content.length ? (
        <Loader fixed />
      ) : (
        <main className="container__main">
          <DisplayContent isPreview={true} />
        </main>
      )}
    </div>
  )
})

export default PreviewView
