import './DigiBlock.scss'

import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import DOMPurify from 'dompurify'
import { useStores } from '../../common/hooks'
import { HTML } from '../../services/Typings'

interface DigiBlockProps {
  title?: string
  url: string
  description?: string
  reference?: string
  source?: HTML
}

const DigiBlock: React.FC<DigiBlockProps> = observer(({ url, title, reference, source, description }) => {
  const { generalStore } = useStores()

  const enhancedUrl = useMemo((): URL => {
    const urlObj = new URL(url)
    urlObj.searchParams.set('lang', generalStore.lang)
    return urlObj
  }, [generalStore.lang, url])

  const calculatedRatio = useMemo((): string => {
    const widthRatio = enhancedUrl.searchParams.get('windowWidth') || '2'
    const heightRatio = enhancedUrl.searchParams.get('windowHeight') || '1'
    return `${(parseInt(heightRatio, 10) / parseInt(widthRatio, 10)) * 100}%`
  }, [enhancedUrl])

  return (
    <section className={BEM()}>
      {title && <h2 className="section">{title}</h2>}
      <div className="digi-block__inner" style={{ paddingBottom: calculatedRatio }}>
        <iframe
          title={title}
          src={enhancedUrl.href}
          className="digi-block__iframe"
          frameBorder="0"
          allow="fullscreen"
          allowFullScreen
        />
      </div>
      {description && (
        <p className="text--small digi-block__description">
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
          {reference && <abbr title={DOMPurify.sanitize(source)}>{reference}</abbr>}
        </p>
      )}
    </section>
  )

  function BEM(): string {
    return cn('digi-block')
  }
})

export default React.memo(DigiBlock)
