import './DisplayLink.scss'

import React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { useStores } from '../../../../common/hooks'
import { NAV_PATHS } from '../../../../common/routing/routerMap'

interface DisplayLinkProps {
  index: number
  isDisabled?: boolean
  title: string
  url: string
}

const DisplayLink: React.FC<DisplayLinkProps> = observer(({ index, title, url, isDisabled }) => {
  const { drawerStore } = useStores()

  return (
    <NavLink
      to={NAV_PATHS.display({ id: url })}
      tabIndex={isDisabled ? -1 : 0}
      className={BEM()}
      style={{ '--drawer-animation-order': index + 2 }}
      onClick={() => drawerStore.toggleDrawer()}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
    />
  )

  function BEM(): string {
    return cn('display-link')
  }
})

export default DisplayLink
