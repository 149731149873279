import './LanguageSelector.scss'

import React, { ReactElement, useMemo } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import Select, { ValueType, OptionTypeBase, OptionsType, IndicatorProps } from 'react-select'
import { LANGUAGE_MAP, LANGUAGES } from '../../common/constants'
import { useStores } from '../../common/hooks'
import Icon, { ICON } from '../Icon/Icon'

interface LanguageSelectProps {
  className?: string
  isDisabled?: boolean
  variant?: 'dark' | 'light'
}

const LanguageSelect: React.FC<LanguageSelectProps> = observer(({ className, isDisabled, variant = 'light' }) => {
  const { generalStore } = useStores()
  const selectedLang = useMemo<ValueType<OptionTypeBase>>(() => {
    return {
      label: LANGUAGE_MAP[generalStore.lang],
      value: generalStore.lang,
    }
  }, [generalStore.lang])

  const options: OptionsType<OptionTypeBase> = Object.values(LANGUAGES).map((key: string) => ({
    label: LANGUAGE_MAP[key],
    value: key,
  }))

  const handleChange = (selected: ValueType<OptionTypeBase>): void => {
    generalStore.changeLang((selected && (selected as OptionTypeBase).value) as LANGUAGES)
  }

  const DropdownIndicator = (props: IndicatorProps<OptionTypeBase>): ReactElement | null => (
    <Icon name={ICON.ARROW} className="react-select__icon" />
  )

  return (
    <Select
      value={selectedLang}
      options={options}
      className={BEM()}
      isDisabled={isDisabled}
      classNamePrefix="react-select"
      components={{ IndicatorSeparator: null, DropdownIndicator: DropdownIndicator }}
      onChange={handleChange}
      isSearchable={false}
    />
  )

  function BEM(): string {
    return cn('react-select', `react-select--${variant}`, className)
  }
})

export default LanguageSelect
