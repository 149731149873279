import i18next from 'i18next'
import { action, observable } from 'mobx'
import { toast } from '../../components/Toast/Toast'
import api from '../../services/Api.services'
import { HTML, ImageDto, ISO8601 } from '../../services/Typings'
import { LANGUAGES, POLLER_FALLBACK_TIME } from '../constants'
import Poller from '../utils/poller'
import { globalStoreInstance } from './Stores'

export interface DrawerGroupDto {
  title: string
  description: HTML
  id: string
  url: string
  displays: DrawerLinkDto[]
}

export interface DrawerLinkDto {
  title: string
  url: string
  image: ImageDto
}

export class DrawerStore {
  @observable drawerOpen = false
  @observable isPageScrolled = false
  @observable groups: DrawerGroupDto[] = []
  @observable isLoading = false
  @observable isLoaded = false
  @observable hasError = false
  @observable lastTagged: ISO8601 = ''
  poller = new Poller()

  @action
  resetDrawer = (): void => {
    this.isLoading = true
    this.isLoaded = false
    this.hasError = false
    this.lastTagged = ''
    this.groups = []
  }

  @action
  startPolling = (): void => {
    this.poller.onPoll(this.fetchDrawerContent)
    this.poller.poll(0)
  }

  @action
  endPolling = (): void => {
    this.poller.endPoll()
  }

  @action
  toggleDrawer = (forceTop?: boolean): void => {
    const drawer = document.getElementById('drawer-element')

    if (forceTop && drawer) {
      drawer.scrollTo(0, 0)
    }

    this.drawerOpen = !this.drawerOpen
  }

  @action
  hideDrawer = (forceTop?: boolean): void => {
    const drawer = document.getElementById('drawer-element')

    if (forceTop && drawer) {
      drawer.scrollTo(0, 0)
    }

    this.drawerOpen = false
  }

  @action
  setPageScroll = (value: boolean): void => {
    this.isPageScrolled = value
  }

  @action
  fetchDrawerContent = async (): Promise<void> => {
    try {
      this.isLoading = true

      const response = await api.drawer.getDrawerList()

      this.isLoading = false
      this.isLoaded = true
      this.lastTagged = response.last_tagged
      this.groups = response.groups

      // when no initial language is set then set it, but don't run any pending requests since the data should be already correctly translated
      if (!localStorage.getItem('lang')) {
        globalStoreInstance.generalStore.changeLang(response.lang.toLowerCase() as LANGUAGES, true)
      }

      this.poller.poll(response.pollTime) // BE will determine polling time
    } catch (e) {
      this.isLoading = false
      this.hasError = true

      if (e?.response?.status === 404) {
        // wrong/missing hash
        this.poller.endPoll()
        globalStoreInstance.generalStore.clearHash()
      } else {
        this.poller.poll(POLLER_FALLBACK_TIME) // if request fails then still keep polling
        toast.error(i18next.t('drawer:request-failed'))
      }
    }
  }
}

export default DrawerStore
