import './FileUploader.scss'

import React, { ChangeEvent, useEffect, useState } from 'react'
import { ArrayHelpers, Field, Formik } from 'formik'
import { observer } from 'mobx-react'
import TextareaAutosize from 'react-autosize-textarea'
import i18next from 'i18next'
import axios from 'axios'
import ReactGA from 'react-ga'
import { withTranslation, WithTranslation } from 'react-i18next'
import { SUPPORTED_DOCUMENT_FORMATS, SUPPORTED_VIDEO_FORMATS } from '../../common/constants'
import { LevelValues } from '../../common/semantics'
import api from '../../services/Api.services'
import { FileDto } from '../../services/Typings'
import Button from '../Button/Button'
import FieldGroup from '../FieldGroup/FieldGroup'
import FileList from '../FileList/FileList'
import Loader from '../Loader/Loader'
import Modal from '../Modal/Modal'
import { toast } from '../Toast/Toast'
import { defaultInitialValues, fieldNames, FormFields } from './FileUploaderFieldNames'
import { FormFields as StoryFormFields } from '../../views/Story/StoryViewFieldNames'
import { validationSchema } from './FileUploaderValidation'

import DocumentPlaceholder from '../../assets/file_placeholder.svg'
import VideoPlaceholder from '../../assets/video_placeholder.svg'

type FileUploaderProps = {
  fileValues: StoryFormFields
} & ArrayHelpers &
  WithTranslation

const FileUploader: React.FC<FileUploaderProps> = observer(({ t, fileValues, push, replace, remove }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [thumb, setThumb] = useState()
  const [initialFile, setInitialFile] = useState<FormFields>()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source() // cancel token for the file upload

  const toggleModal = (): void => {
    setIsModalOpen((prevState) => {
      if (!prevState) {
        ReactGA.modalview('file-upload')
      }
      return !prevState
    })
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
    setInitialFile(undefined)
    setThumb(undefined)
    source.cancel()
  }

  const handleFileChange = (index: number): void => {
    setInitialFile(fileValues.files[index])
    toggleModal()
  }

  const handleSubmit = async (values: FormFields): Promise<void> => {
    try {
      setIsSubmitting(true)

      const response = await api.story.uploadFile(values, source)

      const newFile: FileDto = {
        id: values.id !== undefined ? values.id : fileValues.files.length,
        author: values.author,
        description: values.description,
        file: values.file,
        fileId: response.file_id,
        thumbnail: thumb,
      }

      if (values.id !== undefined) {
        replace(values.id, newFile)
      } else {
        push(newFile)
      }

      setIsSubmitting(false)
      handleModalClose()
    } catch (e) {
      setIsSubmitting(false)

      if (axios.isCancel(e)) {
        toast.warn(i18next.t('story:file-upload.file-upload-cancelled'))
      } else {
        toast.error(i18next.t('story:file-upload.file-upload-failed'))
      }
    }
  }

  const renderModal = (): JSX.Element => {
    return (
      <Modal isOpen={isModalOpen} onRequestClose={handleModalClose}>
        <h2 className="mt-0">{t('story:file-upload.title')}</h2>
        <p>{t('story:file-upload.description')}</p>
        <Formik
          initialValues={initialFile || defaultInitialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleReset, handleSubmit, setFieldValue }) => {
            useEffect(() => {
              if (values.file && !errors.file) {
                if (SUPPORTED_VIDEO_FORMATS.includes(values.file.type)) {
                  setThumb(VideoPlaceholder)
                } else if (SUPPORTED_DOCUMENT_FORMATS.includes(values.file.type)) {
                  setThumb(DocumentPlaceholder)
                } else {
                  setIsLoading(true)
                  const reader = new FileReader()

                  reader.onloadend = () => {
                    setIsLoading(false)
                    setThumb(reader.result)
                  }

                  reader.onerror = () => {
                    setIsLoading(false)
                  }

                  reader.readAsDataURL(values.file)
                }
              }
            }, [values.file, errors.file])

            const handleFileSelect = (event: ChangeEvent<HTMLInputElement>): void => {
              const file: File | undefined = event?.currentTarget?.files ? event.currentTarget.files[0] : undefined

              if (file) {
                setFieldValue(fieldNames.file, file)
              }
            }

            return (
              <form onReset={handleReset} onSubmit={handleSubmit}>
                {values.file && !errors.file ? (
                  <div>
                    {isSubmitting ? <Loader overlay backdrop="white" /> : null}
                    <h3 className="mb-1">{t('story:file-upload.file-description')}</h3>
                    <span className="text--small">{values.file.name}</span>
                    <div className="file-uploader__content">
                      <div className="file-uploader__image">
                        {isLoading ? <Loader center /> : thumb ? <img src={thumb} alt={values.file.name} /> : null}
                      </div>
                      <div>
                        <FieldGroup
                          label={t('story:file-upload.author-label')}
                          id="image-author-id"
                          name={fieldNames.author}
                        >
                          <Field
                            name={fieldNames.author}
                            autoFocus
                            type="text"
                            id="image-author-id"
                            invalid={touched[fieldNames.author] && errors[fieldNames.author]}
                          />
                        </FieldGroup>
                        <FieldGroup
                          label={t('story:file-upload.description-label')}
                          id="image-description-id"
                          name={fieldNames.description}
                        >
                          <Field
                            name={fieldNames.description}
                            as={TextareaAutosize}
                            id="image-description-id"
                            className="file-uploader__description"
                            invalid={touched[fieldNames.description] && errors[fieldNames.description]}
                          />
                        </FieldGroup>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="file-uploader__buttons">
                  <input
                    id="upload-input"
                    name={fieldNames.file}
                    className="file-uploader__file-input"
                    type="file"
                    onChange={handleFileSelect}
                  />
                  <label htmlFor="upload-input" className="btn btn--primary">
                    <span className="btn__label">
                      {values.file ? t('story:file-upload.new-file-button') : t('story:file-upload.add-file-button')}
                    </span>
                  </label>

                  {values.file && !errors.file ? (
                    <Button
                      variant={LevelValues.SECONDARY}
                      type="submit"
                      label={t('story:file-upload.save-file-button')}
                    />
                  ) : null}

                  {errors.file && <div className="field__error file-uploader__error">{errors.file}</div>}
                </div>
              </form>
            )
          }}
        </Formik>
      </Modal>
    )
  }

  return (
    <div>
      <FileList values={fileValues} handleFileChange={handleFileChange} remove={remove} />
      <Button variant={LevelValues.PRIMARY} label={t('story:file-upload.add-file-button')} onClick={toggleModal} />
      {renderModal()}
    </div>
  )
})

export default withTranslation()(FileUploader)
