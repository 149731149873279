import i18next from 'i18next'
import { action, observable } from 'mobx'
import { toast } from '../../components/Toast/Toast'
import api from '../../services/Api.services'
import { BlockDto } from '../../services/controllers/DisplayController'
import { NAV_PATHS } from '../routing/routerMap'
import Poller from '../utils/poller'
import { globalStoreInstance } from './Stores'

export class DisplayStore {
  @observable content: BlockDto[] = []
  @observable isLoading = false
  @observable isLoaded = false
  poller = new Poller()

  @action
  startPolling = (hash: string): void => {
    this.poller.onPoll(() => this.fetchPreviewContent(hash))
    this.poller.poll(0)
  }

  @action
  endPolling = (): void => {
    this.poller.endPoll()
  }

  // used when navigating away from the page to get fresh content next time
  @action
  resetPage = (): void => {
    this.isLoading = false
    this.isLoaded = false
    this.content = []
  }

  @action
  fetchDisplayContent = async (url: string): Promise<void> => {
    try {
      this.isLoading = true

      this.content = await api.display.getDisplay(url)

      this.isLoading = false
      this.isLoaded = true
    } catch (e) {
      this.isLoading = false
      globalStoreInstance.routerStore.history.push(NAV_PATHS.pageNotFound())
      toast.error(i18next.t('general:display-loading-failed'))
    }
  }

  @action
  fetchPreviewContent = async (hash: string): Promise<void> => {
    try {
      this.isLoading = true

      this.content = await api.display.getPreview(hash)

      this.isLoading = false
      this.isLoaded = true

      this.poller.poll(3000) // poll preview every 3s
    } catch (e) {
      this.isLoading = false
      this.poller.endPoll()
      globalStoreInstance.routerStore.history.replace(NAV_PATHS.pageNotFound())
      toast.error(i18next.t('general:display-loading-failed'))
    }
  }
}

export default DisplayStore
