import './Checkbox.scss'
import cn from 'classnames'
import { Field, FieldArray } from 'formik'

import React from 'react'

interface CheckboxProps {
  className?: string
  id: string
  label: string
  name: string
  required?: boolean
  type?: 'checkbox' | 'radio'
  value: string
}

const Checkbox: React.FC<CheckboxProps> = ({ className, type = 'checkbox', id, name, value, label, required }) => {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <Field name={name}>
          {({ field, form }) => {
            const handleChange = (): void => {
              if (type === 'checkbox') {
                if (field.value && field.value.find((arrayValue) => arrayValue === value)) {
                  arrayHelpers.remove(field.value.indexOf(value))
                } else {
                  arrayHelpers.push(value)
                }
              } else {
                form.setFieldValue(name, value)
              }
              form.setFieldTouched(name)
            }

            const isChecked = (): boolean => {
              if (type === 'checkbox') {
                return field.value && !!field.value.find((arrayValue) => arrayValue === value)
              } else {
                return field.value === value
              }
            }

            return (
              <div className={BEM()}>
                <input
                  type={type}
                  id={id}
                  name={field.name}
                  value={value}
                  checked={isChecked()}
                  className="checkbox__input"
                  onChange={handleChange}
                />
                <label htmlFor={id} className="checkbox__label">
                  {label} {required && <span className="text--error">*</span>}
                </label>
              </div>
            )
          }}
        </Field>
      )}
    />
  )

  function BEM(): string {
    return cn('checkbox', className, {
      'checkbox--radio': type === 'radio',
    })
  }
}

export default React.memo(Checkbox)
