import dayjs from 'dayjs'
import { FormikBag } from 'formik'
import i18n from 'i18next'
import throttle from 'lodash-es/throttle'
import { action, observable } from 'mobx'
import ReactGA from 'react-ga'
import { matchPath } from 'react-router-dom'
import { fieldNames, FormFields } from '../../components/HashModal/HashModalFieldNames'
import api from '../../services/Api.services'
import { GDPR } from '../../services/Typings'
import { LANGUAGES } from '../constants'
import { ROUTER_PATHS } from '../routing/routerMap'
import { initializeGA, isMobileView, isTabletView } from '../utils/generalUtil'
import { globalStoreInstance } from './Stores'

export class GeneralStore {
  @observable lang: LANGUAGES = (localStorage.getItem('lang') as LANGUAGES) || LANGUAGES.ET
  @observable hash: string | null = localStorage.getItem('hash')
  @observable isHashModalOpen = false
  @observable validatingHash = false
  @observable gpdr: GDPR = localStorage.getItem('gdpr') as GDPR
  @observable isMobile: boolean = isMobileView()
  @observable isTablet: boolean = isTabletView()
  @observable pendingRequests: Array<{
    name: string
    request: () => void
  }> = []

  constructor() {
    window.addEventListener('resize', this.listenToResize)
  }

  listenToResize = throttle((e?: UIEvent) => {
    this.isMobile = isMobileView()
    this.isTablet = isTabletView()
  }, 100)

  @action
  toggleHashModal = (): void => {
    this.isHashModalOpen = !this.isHashModalOpen

    if (this.isHashModalOpen) {
      ReactGA.modalview('hash-modal')
    }
  }

  @action
  setGdpr = (status: GDPR): void => {
    this.gpdr = status
    localStorage.setItem('gdpr', status)

    if (status === GDPR.AGREED) {
      initializeGA()
      ReactGA.pageview(globalStoreInstance.routerStore.location.pathname + window.location.search)
    }
  }

  @action
  addPendingRequest = (name: string, request: () => void): void => {
    this.pendingRequests.push({ name: name, request: request })
  }

  @action
  removePendingRequest = (name: string): void => {
    this.pendingRequests = this.pendingRequests.filter((request) => request.name !== name)
  }

  @action
  changeLang = (newLang: LANGUAGES, skipPendingRequests?: boolean): void => {
    this.lang = newLang // change store language
    i18n.changeLanguage(newLang) // change i18next lang
    dayjs.locale(newLang)

    // when we have already set the initial language or the user has changed it then we need to re fetch all the data currently on screen
    if (!skipPendingRequests) {
      localStorage.setItem('lang', newLang) // save new lang to LS
      this.pendingRequests.forEach((request) => request.request())
    } else {
      localStorage.setItem('lang', newLang) // save new lang to LS
    }
  }

  @action
  setHash = (hash: string): void => {
    // when setting new has we should clear previous story senders info
    localStorage.removeItem('storyEmail')
    localStorage.removeItem('storyName')
    localStorage.removeItem('storyGender')
    localStorage.removeItem('storyYear')

    localStorage.setItem('hash', hash)
    this.hash = hash
  }

  @action
  clearHash = (): void => {
    localStorage.removeItem('hash')
    this.hash = null
    initializeGA(true)
  }

  @action
  validateHash = async (hash: string, bag: FormikBag<any, FormFields>): Promise<any> => {
    try {
      this.validatingHash = true

      await api.drawer.validateHash(hash) // validate hash

      this.setHash(hash) // when has is valid then set it
      initializeGA()
      this.validatingHash = false
      this.toggleHashModal()

      // re-fire all pending requests
      this.pendingRequests.forEach((request) => request.request())

      // when in overview then open drawer after hash enter
      if (
        matchPath(globalStoreInstance.routerStore.location.pathname, {
          path: ROUTER_PATHS.OVERVIEW,
          exact: true,
        })
      ) {
        globalStoreInstance.drawerStore.toggleDrawer()
      }
    } catch (e) {
      this.clearHash()
      this.validatingHash = false
      bag.setFieldError(fieldNames.hash, i18n.t('general:hash-modal.error'))
    }
  }
}

export default GeneralStore
