import { generatePath } from 'react-router'
import { RouteProps as OverviewRouteProps } from '../../views/Overview/OverviewView'
import { RouteProps as DisplayRouteProps } from '../../views/Display/DisplayView'
import { RouteProps as StoryRouteProps } from '../../views/Story/StoryView'
import { RouteProps as PageNotFoundRouteProps } from '../../views/404/PageNotFoundView'
import { RouteProps as GdprRouteProps } from '../../views/gdpr/GdprView'

// used as Route paths
export const ROUTER_PATHS = {
  OVERVIEW: '/',
  HASH: '/:hash([0-9A-Z]{5})', // ticket HASH only has 5 number/LETTER characters
  PREVIEW: '/preview/:hash([0-9A-Z]{5})', // draft HASH only has 5 number/LETTER characters
  DISPLAY: '/lugu/:id',
  STORY: '/story',
  GDPR: '/gdpr',
  PAGE_NOT_FOUND: '/404',
}

// used for programmatic navigation
export const NAV_PATHS = {
  overview: (p?: ToStringableValues<OverviewRouteProps>) => generatePath(ROUTER_PATHS.OVERVIEW, p),
  display: (p: ToStringableValues<DisplayRouteProps>) => generatePath(ROUTER_PATHS.DISPLAY, p),
  story: (p?: ToStringableValues<StoryRouteProps>) => generatePath(ROUTER_PATHS.STORY, p),
  gdpr: (p?: ToStringableValues<GdprRouteProps>) => generatePath(ROUTER_PATHS.GDPR, p),
  pageNotFound: (p?: ToStringableValues<PageNotFoundRouteProps>) => generatePath(ROUTER_PATHS.PAGE_NOT_FOUND, p),
}
