import './HashModal.scss'

import React from 'react'
import { Field, Formik } from 'formik'
import { observer } from 'mobx-react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useStores } from '../../common/hooks'
import { LevelValues } from '../../common/semantics'
import Button from '../Button/Button'
import FieldGroup from '../FieldGroup/FieldGroup'
import Loader from '../Loader/Loader'
import Modal from '../Modal/Modal'
import { defaultInitialValues, fieldNames, FormFields } from './HashModalFieldNames'
import { validationSchema } from './HashModalValidation'

import ticketExample from '../../assets/step2.svg'

const HashModal: React.FC<WithTranslation> = observer(({ t }) => {
  const { generalStore } = useStores()

  const handleSubmit = (values: FormFields, bag): void => {
    generalStore.validateHash(values.hash, bag)
  }

  return (
    <Modal isOpen={generalStore.isHashModalOpen} onRequestClose={generalStore.toggleHashModal}>
      {generalStore.validatingHash && <Loader overlay backdrop="white" />}
      <Formik
        initialValues={defaultInitialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleReset, handleSubmit }) => {
          return (
            <form onReset={handleReset} onSubmit={handleSubmit}>
              <h3 className="mt-0">{t('general:hash-modal.title')}</h3>
              <div className="hash-modal__flow">
                <img src={ticketExample} alt={t('general:hash-modal.image-alt')} className="hash-modal__ticket" />
                <p>{t('general:hash-modal.description')}</p>
              </div>
              <FieldGroup label={t('general:code')} id="hash-id" name={fieldNames.hash}>
                <>
                  <Field
                    id="hash-id"
                    name={fieldNames.hash}
                    autoFocus
                    invalid={touched[fieldNames.hash] && errors[fieldNames.hash]}
                    maxLength="5"
                    className="input--small"
                  />
                </>
              </FieldGroup>
              <Button variant={LevelValues.SECONDARY} type="submit" label={t('general:confirm')} />
            </form>
          )
        }}
      </Formik>
    </Modal>
  )
})

export default withTranslation()(HashModal)
