import i18next from 'i18next'
import * as Yup from 'yup'
import { FILE_SIZE_LIMIT, FILE_TYPE_RESTRICTION } from '../../common/constants'
import { fieldNames } from './FileUploaderFieldNames'

export const validationSchema = Yup.lazy((values) =>
  Yup.object().shape({
    [fieldNames.file]: Yup.mixed()
      .test('fileType', i18next.t('story:file-upload.validation.unsupported-file'), (value) =>
        FILE_TYPE_RESTRICTION.includes(value.type),
      )
      .test('fileSize', i18next.t('story:file-upload.validation.large-file'), (value) => value.size <= FILE_SIZE_LIMIT),
    [fieldNames.author]: Yup.string().min(3, i18next.t('story:file-upload.validation.author-min')),
    [fieldNames.description]: Yup.string().min(5, i18next.t('story:file-upload.validation.description-min')),
  }),
)
