import createFieldNameConstants from '../../common/utils/generalUtil'

export interface FormFields {
  id: number | undefined
  author: string
  description: string
  file: File | undefined
}

export const defaultInitialValues: FormFields = {
  id: undefined,
  author: '',
  description: '',
  file: undefined,
}

export const fieldNames = createFieldNameConstants(defaultInitialValues)
