import './Video.scss'

import React from 'react'
import { useStores } from '../../common/hooks'
import { observer } from 'mobx-react'

interface VideoProps {
  title?: string
  url: string
}

const Video: React.FC<VideoProps> = observer(({ url, title }) => {
  const { generalStore } = useStores()

  return (
    <div className="video">
      <iframe
        title={title}
        src={`${url}?texttrack=${generalStore.lang}`}
        className="video__iframe"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  )
})

export default React.memo(Video)
