import axios from 'axios'

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // this comes from build service environment variables
  timeout: 100000,
})

axiosClient.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        hash: config.headers.hash || localStorage.getItem('hash') || '', // order: hash from controller -> has from LS -> fallback
        lang: localStorage.getItem('lang') || '',
      },
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosClient.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default axiosClient
