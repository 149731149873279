import './CloseButton.scss'

import React from 'react'
import cn from 'classnames'

interface CloseButtonProps {
  className?: string
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
  label: string
  inverted?: boolean
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, inverted, className, label }) => {
  return (
    <button type="button" aria-label={label} className={BEM()} onClick={onClick}>
      <span />
    </button>
  )

  function BEM(): string {
    return cn('close-button', className, {
      'close-button--inverted': inverted,
    })
  }
}

export default React.memo(CloseButton)
