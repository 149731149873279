import React from 'react'
import cn from 'classnames'
import { WithTranslation, withTranslation } from 'react-i18next'

export interface ExtraLink {
  title?: string
  url: string
}

interface ExtraLinksBlockProps {
  extraLinks: ExtraLink[]
}

const ExtraLinksBlock: React.FC<ExtraLinksBlockProps & WithTranslation> = ({ t, extraLinks = [] }) => {
  return extraLinks.length ? (
    <section className={BEM()}>
      <h2>{t('general:blocks.extra-links.title')}</h2>
      {extraLinks.map((link, index) => (
        <div key={index}>
          {link.title && <p>{link.title}</p>}
          <a href={link.url} className="text--break" target="_blank" rel="noopener noreferrer">
            {link.url}
          </a>
        </div>
      ))}
    </section>
  ) : null

  function BEM(): string {
    return cn('extra-links-block')
  }
}

export default withTranslation()(React.memo(ExtraLinksBlock))
