import './PageNotFoundView.scss'

import React from 'react'
import { observer } from 'mobx-react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'

export type RouteProps = {}

export type StoryViewProps = RouteComponentProps<RouteProps> & WithTranslation

const PageNotFoundView: React.FC<StoryViewProps> = observer(({ t }) => {
  return (
    <div className="container page-not-found">
      <main className="container__main page-not-found__main">
        <div className="container__inner page-not-found__inner">
          <section className="page-not-found__section">
            <div className="page-not-found__block">
              <span className="page-not-found__corner page-not-found__corner--1" />
              <span className="page-not-found__corner page-not-found__corner--2" />
              <span className="page-not-found__corner page-not-found__corner--3" />
              <span className="page-not-found__corner page-not-found__corner--4" />
              <h1 className="page-not-found__title">{t('general:page-not-found-title')}</h1>
              <p>{t('general:page-not-found-description')}</p>
              <p className="mt-0">
                {t('general:page-not-found-link-text')}
                <Link to="/">{t('general:page-not-found-link-button')}</Link>
              </p>
            </div>
          </section>
        </div>
      </main>
    </div>
  )
})

export default withTranslation()(PageNotFoundView)
