import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import { observer } from 'mobx-react'
import { RemoveScroll } from 'react-remove-scroll'
import { useStores } from '../../common/hooks'
import CloseButton from '../CloseButton/CloseButton'

interface ModalProps extends ReactModal.Props {
  size?: 'medium' | 'large'
  padding?: string
  paddingMobile?: string
  type?: 'success'
}

const Modal: React.FC<ModalProps & WithTranslation> = observer(
  ({ t, size = 'medium', closeTimeoutMS = 300, padding, type, paddingMobile, style = {}, children, ...rest }) => {
    const { generalStore } = useStores()

    const maxSize = (): string => {
      switch (size) {
        case 'large':
          return '67rem'
        case 'medium':
        default:
          return '51rem'
      }
    }

    const customStyles: ReactModal.Styles = {
      overlay: {
        zIndex: '12',
        display: 'flex',
        bottom: 'auto',
        right: 'auto',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.5)',
        ...style.overlay,
      },
      content: {
        position: 'relative',
        top: generalStore.isMobile ? '0' : 'auto',
        left: generalStore.isMobile ? '0' : 'auto',
        right: 'auto',
        bottom: 'auto',
        width: generalStore.isMobile ? '100vw' : 'auto',
        height: generalStore.isMobile ? '100vh' : 'auto',
        margin: generalStore.isMobile ? '0' : '3rem 6rem',
        padding: generalStore.isMobile ? '2rem' : '4rem',
        border: 'none',
        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 'none',
        outline: '0',
        background:
          type === 'success'
            ? getComputedStyle(document.documentElement).getPropertyValue('--color-success')
            : getComputedStyle(document.documentElement).getPropertyValue('--color-white'),
        flex: `0 1 ${maxSize()}`,
        maxHeight: generalStore.isMobile ? 'none' : '50rem',
        ...style.content,
      },
    }

    return (
      <RemoveScroll enabled={rest.isOpen}>
        <ReactModal {...rest} closeTimeoutMS={300} style={customStyles}>
          {rest.onRequestClose && (
            <CloseButton label={t('general:close-modal')} className="modal__close" onClick={rest.onRequestClose} />
          )}
          <div className="modal__content">{children}</div>
        </ReactModal>
      </RemoveScroll>
    )
  },
)

export default withTranslation()(Modal)
