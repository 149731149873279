import { AxiosInstance } from 'axios'
import { LANGUAGES } from '../../common/constants'
import { DrawerGroupDto } from '../../common/store/DrawerStore'
import { HTML, ISO8601 } from '../Typings'

export interface DrawerListResponse {
  title: string
  pollTime: number
  description: HTML
  lang: LANGUAGES
  last_tagged: ISO8601
  groups: DrawerGroupDto[]
}

export default class DrawerController {
  constructor(private api: AxiosInstance) {}

  getDrawerList = async (): Promise<DrawerListResponse> => {
    return this.api.get(`/displays`)
  }

  validateHash = async (hash: string): Promise<any> => {
    return this.api.get(`/validate-hash`, {
      headers: {
        hash: hash,
      },
    })
  }
}
