import './LargeImageBlock.scss'

import React from 'react'
import cn from 'classnames'
import DOMPurify from 'dompurify'
import { generateSrcSet } from '../../common/utils/generalUtil'
import { ImageDto } from '../../services/Typings'

interface LargeImageBlockProps {
  image: ImageDto
}

const LargeImageBlock: React.FC<LargeImageBlockProps> = ({ image }) => {
  return (
    <section className={BEM()}>
      <figure className="large-image-block__figure">
        <img
          src={image.url}
          srcSet={generateSrcSet(image.url)}
          alt={image.alt_text}
          className="large-image-block__image"
        />
        {image.alt_text && (
          <figcaption className="large-image-block__description">
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(image.alt_text) }} />
            {image.reference && <abbr title={DOMPurify.sanitize(image.source)}>{image.reference}</abbr>}
          </figcaption>
        )}
      </figure>
    </section>
  )

  function BEM(): string {
    return cn('large-image-block')
  }
}

export default React.memo(LargeImageBlock)
