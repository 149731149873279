import { AxiosInstance, CancelTokenSource } from 'axios'
import {
  FormFields as FileFormField,
  fieldNames as fileFieldNames,
} from '../../components/FileUploader/FileUploaderFieldNames'
import { FormFields as StoryFormField, fieldNames as storyFieldNames } from '../../views/Story/StoryViewFieldNames'

interface FileUploadResponse {
  file_id: string
}

type StorySubmitResponse = {}

export default class StoryController {
  constructor(private api: AxiosInstance) {}

  uploadFile = async (data: FileFormField, source: CancelTokenSource): Promise<FileUploadResponse> => {
    const formData = new FormData()

    formData.set(fileFieldNames.author, data.author)
    formData.set(fileFieldNames.description, data.description)
    formData.append(fileFieldNames.file, data.file || '')

    return this.api.post(`/story/files`, formData, {
      cancelToken: source.token,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  formSubmit = async (data: StoryFormField, url: string | undefined): Promise<StorySubmitResponse> => {
    const formData = new FormData()

    formData.set(storyFieldNames.name, data.name)
    formData.set(storyFieldNames.email, data.email)
    formData.set(storyFieldNames.story, data.story)
    formData.set(storyFieldNames.gender, data.gender || '')
    formData.set(storyFieldNames.year, data.year)
    formData.set(storyFieldNames.agreement, data.agreement[0])
    formData.set('display', url || '')
    formData.set(storyFieldNames.files, JSON.stringify(data.files.map((file) => file.fileId)))

    return this.api.post(`/story`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}
