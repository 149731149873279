import { AxiosInstance } from 'axios'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { ExtraLink } from '../../components/ExtraLinksBlock/ExtraLinksBlock'
import { BLOCK_TYPE, HTML, ImageDto, LiteratureReferenceDto, MediaReferenceDto, VideoDto } from '../Typings'

export type BlockDto =
  | HeaderBlockDto
  | TextBlockDto
  | TextImageBlockDto
  | PictureBlockDto
  | PictureCompareBlockDto
  | VideoBlockDto
  | GalleryBlockDto
  | DigiBlockDto
  | ReferenceDisplayDto
  | QuoteBlockDto
  | ExtraLinksBlockDto
  | ReferenceBlockDto

export interface HeaderBlockDto {
  type: BLOCK_TYPE.HEADER
  title: string
  intro: HTML
  author?: string
  image: ImageDto
  breadcrumbs: Breadcrumb[]
}

export interface TextBlockDto {
  type: BLOCK_TYPE.TEXT
  title?: string
  text: HTML
}

export interface TextImageBlockDto {
  type: BLOCK_TYPE.TEXT_IMAGE
  title?: string
  text: HTML
  img_align: 'left' | 'right'
  image: ImageDto
}

export interface PictureBlockDto {
  type: BLOCK_TYPE.PICTURE
  image: ImageDto
}

export interface PictureCompareBlockDto {
  type: BLOCK_TYPE.PICTURE_COMPARE
  title?: string
  images: ImageDto[]
}

export interface VideoBlockDto {
  type: BLOCK_TYPE.VIDEO
  title?: string
  video: VideoDto
}

export interface GalleryBlockDto {
  type: BLOCK_TYPE.GALLERY
  title?: string
  images: Array<
    ImageDto & {
      thumbnail_url: string
    }
  >
}

export interface DigiBlockDto {
  type: BLOCK_TYPE.DIGI
  title?: string
  alt_text?: string
  reference?: string
  source?: HTML
  url: string
}

export interface ReferenceDisplayDto {
  type: BLOCK_TYPE.REFERENCE_DISPLAY
  blocks: BlockDto[]
}

export interface QuoteBlockDto {
  type: BLOCK_TYPE.QUOTE
  text: string
}

export interface ExtraLinksBlockDto {
  type: BLOCK_TYPE.EXTRA_LINKS
  links: ExtraLink[]
}

export interface ReferenceBlockDto {
  type: BLOCK_TYPE.REFERENCE
  references: {
    media: MediaReferenceDto[]
    literature: LiteratureReferenceDto[]
  }
}

export type DisplayResponse = BlockDto[]

export default class DisplayController {
  constructor(private api: AxiosInstance) {}

  getDisplay = async (url: string): Promise<DisplayResponse> => {
    return this.api.get(`/display/${url}`)
  }

  getPreview = async (hash: string): Promise<DisplayResponse> => {
    return this.api.get(`/preview/${hash}`)
  }
}
