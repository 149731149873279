import './GalleryBlock.scss'

import React, { useState } from 'react'
import cn from 'classnames'
import { WithTranslation, withTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStores } from '../../common/hooks'
import { LevelValues } from '../../common/semantics'
import { generateSrcSet } from '../../common/utils/generalUtil'
import { GalleryItemDto } from '../../services/Typings'
import Button from '../Button/Button'
import LightBox from '../Lightbox/Lightbox'

type GalleryBlockProps = {
  title?: string
  items: GalleryItemDto[]
} & WithTranslation

const GalleryBlock: React.FC<GalleryBlockProps> = observer(({ t, title, items = [] }) => {
  const { generalStore } = useStores()
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)
  const [initialIndex, setInitialIndex] = useState(0)
  const [showAll, setShowAll] = useState(false)
  const limit = generalStore.isMobile ? 8 : 16 // show 8 on mobile and 16 on desktop

  const toggleLightBox = (index?: number): void => {
    setInitialIndex((prevState) => (index !== undefined ? index : prevState))
    setIsLightBoxOpen((prevState) => !prevState)
  }

  const closeLightBox = (): void => {
    setIsLightBoxOpen((prevState) => false)
  }

  const toggleShowAll = (): void => {
    setShowAll((prevState) => !prevState)
  }

  return (
    <section className={BEM()}>
      {title && <h2>{title}</h2>}
      <div className="gallery-block__items">
        {items.slice(0, showAll ? items.length : limit).map((item, index) => (
          <button key={index} type="button" className="gallery-block__button" onClick={() => toggleLightBox(index)}>
            <figure className="gallery-block__figure">
              <div className="gallery-block__ratio">
                <img
                  src={item.thumbnail_url}
                  srcSet={generateSrcSet(item.thumbnail_url)}
                  alt={item.alt_text}
                  className="gallery-block__thumbnail"
                />
              </div>
            </figure>
          </button>
        ))}
      </div>
      {items.length > limit && (
        <Button
          onClick={toggleShowAll}
          variant={LevelValues.PRIMARY}
          className="gallery-block__show-more"
          label={showAll ? t('general:blocks.gallery.show-less') : t('general:blocks.gallery.show-more')}
        />
      )}
      <LightBox
        title={title}
        isOpen={isLightBoxOpen}
        initialIndex={initialIndex}
        closeModal={closeLightBox}
        items={items}
      />
    </section>
  )

  function BEM(): string {
    return cn('gallery-block', {
      'gallery-block--show-all': showAll,
    })
  }
})

export default withTranslation()(GalleryBlock)
