import './Banner.scss'

import React from 'react'
import cn from 'classnames'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'

import { ReactComponent as BannerArrow } from '../../../../assets/banner_arrow.svg'

const Banner: React.FC<WithTranslation> = () => {
  return (
    <div className={BEM()}>
      <div className="container__inner banner__inner">
        <h1 className="banner__title">
          <span className="banner__corner banner__corner--1" />
          <span className="banner__corner banner__corner--2" />
          <span className="banner__corner banner__corner--3" />
          <span className="banner__corner banner__corner--4" />
          <Trans i18nKey="overview:brand-title" />
          <BannerArrow className="banner__icon" />
        </h1>
      </div>
    </div>
  )

  function BEM(): string {
    return cn('banner')
  }
}

export default withTranslation()(Banner)
