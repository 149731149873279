import i18next from 'i18next'
import * as Yup from 'yup'
import { fieldNames } from './HashModalFieldNames'

export const validationSchema = Yup.lazy((values) =>
  Yup.object().shape({
    [fieldNames.hash]: Yup.string()
      .required(i18next.t('general:hash-modal.validation.required'))
      .length(5, i18next.t('general:hash-modal.validation.length')),
  }),
)
