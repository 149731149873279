import { AxiosInstance } from 'axios'
import { LANGUAGES } from '../../common/constants'
import { ImageDto } from '../Typings'

export interface ExhibitionListResponse {
  pollTime: number
  lang: LANGUAGES
  exhibitions: Exhibition[]
}

export interface Exhibition {
  id: string
  title: string
  image: ImageDto
  url: string
}

export default class ExhibitionController {
  constructor(private api: AxiosInstance) {}

  getExhibitionList = async (): Promise<ExhibitionListResponse> => {
    return this.api.get('/visit')
  }
}
