import React from 'react'
import { observer } from 'mobx-react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useStores } from '../../common/hooks'
import { LevelValues } from '../../common/semantics'
import Button from '../Button/Button'

interface NoHashBlockProps {
  onClick?: () => void
}

const NoHashBlock: React.FC<NoHashBlockProps & WithTranslation> = observer(({ t, onClick }) => {
  const { generalStore } = useStores()

  const handleClick = (): void => {
    if (onClick) {
      onClick()
    }

    generalStore.toggleHashModal()
  }

  return (
    <div>
      <p className="text--limit-lg mt-0">{t('drawer:ticket-tutorial')}</p>
      <Button variant={LevelValues.SECONDARY} onClick={handleClick} label={t('general:enter')} />
    </div>
  )
})

export default withTranslation()(NoHashBlock)
